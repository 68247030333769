import { env } from '@/libs/env'
import axios, { AxiosError, AxiosResponse } from 'axios'
import dayjs, { Dayjs } from 'dayjs'
import moment from 'moment'
import qs from 'qs'
import 'dayjs/locale/ja'

// models
import { IdentificationType } from '@/models/self-checkin/identification'
import { ReservationUserType } from '@/models/reservation/reservation-user'
import { ReservationGuestType } from '@/models/reservation/reservation-guest'
import { AdminReservationResponseType } from '@/models/reservation/admin-reservation'
import { ReservationData } from '@/models/reservation/reservation-data'
import { GuestRoomAssignResponseType } from '@/models/room-manager/response/guest-room-assign'
import { GuestRoomAssignResponseType as GuestRoomAssignForFreeResponseType } from '@/models/guest-room/guest-room-assign'
import { ReportTypes } from '@/models/report'
import { onlyForDev } from '@/utils/check-environment'

import { searchMockResult } from '@/apis/mock'
import * as auth0 from '@/apis/auth0'
import { AdvanceMoneySearchType } from '@/models/sales-manager/advance-money'
import { CleaningStatusType, RoomUsageStatusType } from '@/constants/cleaning-manager'
import { TerminalType } from '@/models/smaregi/terminal'
import { deviceSettingResponseType } from '@/models/device/device-setting'
import { CsvUploadHistoryResponseType } from '@/models/csv-upload-history/csv-upload-history'
import { TransactionSideType, TransactionType } from '@/models/smaregi/transaction'
import { StaffInputType } from '@/models/staff'
import { errorHandler } from '@/libs/errors'
import { groupBy, isEqual } from 'lodash'
import * as MobileRequest from '@/models/mobile-request'
import * as CLeaningManager from '@/constants/cleaning-manager'
import { GuestRoomTypeSelectType, NewGuestRoomTypeType } from '@/models/guest-room-type'
import { DeviceSendHistory } from '@/models/device/device-send-history'
import { OperationLogType, OperationLogFiltersType, EmployeeType } from '@/models/operation-log'
import * as SiteControllerStockType from '@/models/room-manager/response/site-controller-stock'
import { EntireSearchContentType } from '@/models/entire-search'
import * as GuestRoomIndicator from '@/models/room-manager/response/guest-room-indicator'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { AssetType, FormatType, MapCategoryType, SpaceCreateAndUpdateRequestType } from '@/models/guest-app/asset'
import { DesignType, ThemeType } from '@/models/guest-app/design'
import { AssetFreeDetailFormValue } from '@/components/organisms/settings/guest-app/free-detail-modal'
import { AssetPulldownDetailFormValue } from '@/components/organisms/settings/guest-app/pulldown-asset-detail'
import { CustomCheckinListResponseType, CustomCheckinResponseType } from '@/models/self-checkin/custom-checkin'
import { AssetMapDetailFormValue } from '@/components/organisms/settings/guest-app/map-detail-modal'
import { AssetNewsDetailFormValue } from '@/components/organisms/settings/guest-app/news-detail-modal'
import { AssetButtonFormValue } from '@/components/organisms/settings/guest-app/button-asset-detail'
import { AssetImageDetailFormValue } from '@/components/organisms/settings/guest-app/image-detail-modal'
import { AssetCouponDetailFormValue } from '@/components/organisms/settings/guest-app/coupon-detail-modal'
import { AssetProductDetailFormValue } from '@/components/organisms/settings/guest-app/request-product-detail-modal'
import { SearchReservationTypeWhenCheckedIn, SearchReservationTypeWhenNonCheckIn } from '@/models/reservation'
import { CalculatedSalesSubjectSetType, SalesSubjectSetSettingType, SalesType } from '@/models/sales'
import { MailDeliveryContentInput } from '@/models/mail-delivery/mail-delivery-content'
import { GetMailDeliveryHistoryParams } from '@/models/mail-delivery/mail-delivery-history'
import { SalesDepartmentMasterType, SalesSubSubjectMasterType, SalesSubjectType } from '@/models/sales-manager/sales'
import { FormValue as SalesSubjectFormValue } from '@/components/organisms/settings/sales-manager/subject-detail-modal'
import { AlignmentReservationInput, NewReservationInput, SpaceReservation, UpdateSpaceReservationInput } from '@/models/space-manager'
import { AccountInfo, Device, LockSetting, LockType, RoomKindType } from '@/models/lock-setting'
import { AdminAccount, AdminAccountUpdateParameter } from '@/models/admin-account'
import * as SalesInvoice from '@/models/sales-invoice'
import { DashboardSalesType } from '@/models/sales-manager/dashboard'
import { ListType } from '@/components/pages/setting/guest-room/key-number'
import * as SalesAccountingModel from '@/models/sales-manager/sales-accounting'
import { GuestRoomAutoAssignSettingType } from '@/components/pages/setting/guest-room/room/guest-room-auto-assign'
import { GuestRoomFloorType } from '@/models/guest-room-floor'
import { GuestRoomNumberType } from '@/models/guest-room-number'
import { ReservationNote, ReservationNoteCategory } from '@/models/reservation/reservation-note'
import { CustomerTag, CustomerTagMasterType, CustomerTagType } from '@/models/customer-manager/customer-tag'
import { PaymentSettingType } from '@/models/payment-setting'

const ourRequest = axios.CancelToken.source()
const apiStore: {
  lastMustShowError?: AxiosError
  lastMustShowErrorTimer: NodeJS.Timeout | undefined
} = {
  lastMustShowError: undefined,
  lastMustShowErrorTimer: undefined,
}

export type HttpParams = {
  url: string
  method: 'get' | 'post' | 'put' | 'delete' | 'patch'
  headers?: { Authorization: any }
  params?: any
  data?: any
  responseType?: any
  paramsSerializer?: (params: any) => string
}

export const LatestVersionKey = 'latestVersion'
export const CurrentVersionKey = 'currentVersion'

async function http<T = any>(config: HttpParams): Promise<AxiosResponse<T>> {
  try {
    if (onlyForDev) {
      const mockResult = await searchMockResult(config)

      if (mockResult) return mockResult
    }

    const { params, data, responseType, paramsSerializer } = config
    const headers = config.headers || {}
    headers['x-front-path'] = window?.location?.pathname || 'N/A'
    headers['x-version'] = sessionStorage.getItem(CurrentVersionKey) || 'N/A'
    const Authorization = getIdToken()
    if (Authorization) headers['Authorization'] = Authorization

    axios.interceptors.response.use(response => {
      const latestVersion = response.headers['x-hotel-version']
      if (!latestVersion) {
        return response
      }
      sessionStorage.setItem(LatestVersionKey, latestVersion)
      const currentVersion = sessionStorage.getItem(CurrentVersionKey)
      if (!currentVersion) {
        sessionStorage.setItem(CurrentVersionKey, latestVersion)
      }
      return response
    })

    switch (config.method) {
      case 'get':
        return await axios.get<T>(config.url, {
          headers,
          params,
          data,
          responseType,
          cancelToken: ourRequest.token,
          paramsSerializer,
          withCredentials: true,
        })
      case 'post':
        return await axios.post<T>(config.url, data, { headers, params, cancelToken: ourRequest.token, withCredentials: true })
      case 'patch':
        return await axios.patch<T>(config.url, data, { headers, params, cancelToken: ourRequest.token, withCredentials: true })
      case 'put':
        return await axios.put<T>(config.url, data, { headers, params, cancelToken: ourRequest.token, withCredentials: true })
      case 'delete':
        return await axios.delete<T>(config.url, {
          headers,
          params,
          data,
          cancelToken: ourRequest.token,
          paramsSerializer,
          withCredentials: true,
        })
      default:
        throw new Error('not reachable')
    }
  } catch (e) {
    const error = e as AxiosError
    const status = error?.response?.status
    const mustShow = error?.response?.data?.mustShow

    if (status === 401) logout()

    if (mustShow) {
      if (isEqual(apiStore.lastMustShowError, error.response?.data)) return Promise.reject(error)
      if (apiStore.lastMustShowErrorTimer) clearTimeout(apiStore.lastMustShowErrorTimer)

      apiStore.lastMustShowError = error.response?.data
      apiStore.lastMustShowErrorTimer = setTimeout(() => {
        apiStore.lastMustShowError = undefined
      }, 3000)

      errorHandler(error, { mustShow: true })
    }

    return Promise.reject(error)
  }
}

const logout = () => {
  ourRequest.cancel()
  auth0.signout()
  localStorage.removeItem('idToken')
}

function getIdToken(): string | null {
  return localStorage.getItem('idToken')
}

export const fetchSelfCheckinPrint = (params: { hotelId: string; checkinId: string }) => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/self-checkin/print`,
    params,
  }).then(results => results.data)
}

/**
 * 未読数情報 取得API

 */
export const fetchCheckinUnreadTotal = () => {
  return http<Aipass.GetCheckinUnreadTotalResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-checkin-unread-total`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 会社情報 取得API
 */
export const fetchAdminAccount = () => {
  return http<AdminAccount>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-account`,
  })
    .then(results => results.data)
    .catch(e => {
      const status = e?.response?.status
      if (status === 403) {
        alert('IPアドレスが許可されていないため、ログインできませんでした')
        logout()
      }
      console.warn(e)
    })
}

/**
 * 会社情報 更新API
 */
export const updateAdminAccount = (data = {}) => {
  return http<AdminAccountUpdateParameter>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-account`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 館内案内情報 基本情報取得API
 */
export const fetchFacilityBasicInfo = () => {
  return http<Aipass.GetAdminFacilityResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-facility`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 機器送信履歴情報取得API
 */
export const fetchDeviceSendHistory = (printerSettingIds: string[]) => {
  const params = { printerSettingIds }
  const Authorization = getIdToken()
  const headers = { Authorization }
  return http<{ deviceSendHistory: DeviceSendHistory[] }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/device-send-history`,
    headers,
    params,
  })
    .then(results => results.data?.deviceSendHistory)
    .catch(e => console.warn(e))
}

/**
 * 機器送信履歴情報更新API
 */
export const updateDeviceSendHistory = (data: { deviceSendHistoryId: string; errorMessage: string | null }) => {
  const Authorization = getIdToken()
  const headers = { Authorization }
  return http<Aipass.PutDeviceSendHistoryResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/device-send-history`,
    headers,
    data,
  })
}

/**
 * 施設情報ステータスを取得するAPI
 */
export const fetchFacilityStatusInfo = () => {
  return http<Aipass.GetAdminFacilityStatusResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-facility-status`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 施設情報ステータスを更新するAPI
 */
export const updateFacilityStatusInfo = (pageIndex, status) => {
  const data = { pageIndex, status }
  return http<Aipass.PutAdminFacilityStatusResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-facility-status`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 館内案内情報 基本情報更新API
 */
export const updateFacilityBasicInfo = data => {
  return http<Aipass.PutAdminFacilityResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-facility`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 館内案内情報: その他の情報を取得するAPI
 * 3) WiFi 4) お知らせ 5) フロア案内 6) 約款 7) 朝食・夕食の会場 8) 浴場 9) 設備
 */
export const fetchFacilityAdditionalInfo = pageIndex => {
  const params = { pageIndex: Number(pageIndex) }
  return http<Aipass.GetAdminFacilityAdditionalResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-facility-additional`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 館内案内情報: その他の情報を更新するAPI
 * 3) WiFi 4) お知らせ 5) フロア案内 6) 約款 7) 朝食・夕食の会場 8) 浴場 9) 設備

 * @param pageIndex
 */
export const updateFacilityAdditionalInfo = data => {
  return http<Aipass.PutAdminFacilityAdditionalResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-facility-additional`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 館内案内情報: アクセス情報を取得するAPI
 */
export const fetchFacilityAccessInfo = () => {
  return http<Aipass.GetAdminFacilityAccessResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-facility-access`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 館内案内情報: アクセス情報を更新するAPI

 * @param data
 */
export const updateFacilityAccessInfo = data => {
  return http<Aipass.PutAdminFacilityAccessResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-facility-access`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * チェックイン情報一覧 取得API
 */
export const fetchAdminCheckinList = (
  page = 0,
  limit,
  name,
  nameKana,
  searchReservationId,
  roomNumber,
  searchBeginDate: string | undefined,
  searchEndDate: string | undefined,
) => {
  const params = {
    page,
    limit,
    name,
    nameKana,
    searchReservationId,
    roomNumber,
    searchBeginDate: searchBeginDate ? dayjs(searchBeginDate).unix() : null,
    searchEndDate: searchEndDate ? dayjs(searchEndDate).unix() : null,
  }

  return http<Aipass.GetAdminCheckinListResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-checkin-list`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * チェックイン情報一覧 更新API
 */
export const updateAdminCheckinList = (data = {}) => {
  return http<Aipass.PutAdminCheckinListResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-checkin-list`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 予約既読 更新API
 */
export const updateAdminReservationRead = reservationId => {
  const data = {
    reservationId,
    mode: 'reservationRead',
  }

  return http<Aipass.PutAdminReservationResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-reservation`,
    data,
  }).then(results => results.data)
}

/**
 * チェックイン情報 取得API
 */
export const fetchAdminCheckin = checkinId => {
  return http<Aipass.GetAdminCheckinResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-checkin?checkinId=${checkinId}`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * チェックイン情報 更新API
 */
export const updateAdminCheckin = (data = {}) => {
  return http<Aipass.PutAdminCheckinResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-checkin`,
    data,
  }).then(results => results.data)
}

/**
 * チェックイン身分証を更新するAPI
 */
export const updateCheckinIdentification = (_identification: IdentificationType[]) => {
  const identification = _identification
  _identification.map((v, index) => {
    identification[index]['isDriversLicense'] = Number(v.isDriversLicense)
  })
  const data = { identification }
  return http<Aipass.PostAdminCheckinIdentificationResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/hotel/admin-checkin-identification`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * チェックイン身分証を削除するAPI
 */
export const deleteCheckinIdentification = deleteIdentifications => {
  const deleteImageIds = deleteIdentifications.map(v => {
    return v.id
  })
  const params = { deleteIdentifications: deleteImageIds }
  return http<Aipass.PostAdminCheckinIdentificationResponse>({
    method: 'delete',
    url: `${env('BASE_API_V3')}/admin-checkin-identification`,
    params,
    paramsSerializer: params => {
      return qs.stringify(params)
    },
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * チェックインメモ情報 取得API
 */
export const fetchCheckinMemo = checkinId => {
  const params = {
    checkinId,
  }

  return http<Aipass.GetCheckinNoteResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-checkin-note`,
    params,
  })
    .then(results => results.data.notes)
    .catch(e => console.warn(e))
}

/**
 * チェックインメモ情報 更新API
 */
export const updateCheckinMemo = (checkinId, noteId, memoText = '') => {
  const data = { checkinId, noteId, memo: memoText }

  return http<Aipass.PostCheckinNoteResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-checkin-note`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * チェックインレビュー情報を取得するAPI
 */
export const fetchCheckinReview = checkinId => {
  const params = {
    checkinId,
  }

  return http<Aipass.GetCheckinReviewResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-checkin-review`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * チェックアウト時のレビュー情報を取得するAPI
 */
export const fetchReservationReview = reservationId => {
  const params = { reservationId }

  return http<Aipass.GetAdminReservationReviewResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-reservation-review`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * ダッシュボード情報を取得するAPI
 */
export const fetchAdminDashboard = (dateOf: Dayjs) => {
  const params = { dateOf: dateOf.format('YYYY-MM-DD') }
  return http<Aipass.GetDashboardTotalResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/dashboard-total`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * チェックインメモの既読処理API
 */
export const putAdminReadCheckinNote = (checkinId, noteId) => {
  const data = { checkinId, noteId }

  return http<Aipass.PutAdminReadCheckinNoteResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-read-checkin-note`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 予約メモの既読処理API
 */
export const putAdminReadReservationNote = (reservationId, noteId) => {
  const data = { reservationId, noteId }

  return http<Aipass.PutAdminReadReservationNoteResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-read-reservation-note`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 評価の既読処理API
 */
export const putAdminReadReview = checkinId => {
  const data = { checkinId }

  return http<Aipass.PutAdminReadReviewResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-read-review`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 周辺情報を取得するAPI
 */
export const fetchAroundSpots = hotelId => {
  const params = {
    hotelId,
  }

  return http<Aipass.GetAdminAroundSpotResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-around-spot`,
    params,
  })
    .then(results => results.data.aroundSpots)
    .catch(e => console.warn(e))
}

/**
 * 周辺情報を取得するAPI
 */
export const updateAroundSpots = aroundSpots => {
  const data = { aroundSpots }
  return http<Aipass.PostAdminAroundSpotResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/admin-around-spot`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 予約情報を一覧取得するAPI
 */
export const fetchReservation = (
  params: EntireSearchContentType & {
    page: number
    limit: number
  },
) => {
  Object.assign(params, {
    searchBeginDate: params.beginDate ? dayjs(params.beginDate).format('YYYY-MM-DD') : undefined,
    searchEndDate: params.endDate ? dayjs(params.endDate).format('YYYY-MM-DD') : undefined,
  })

  return http<Aipass.GetAdminReservationResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-reservation`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}
/**
 * 予約情報を取得するAPI
 */
export const fetchReservationDetail = (params: { reservationId: string }) => {
  return http<Aipass.GetAdminReservationResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-reservation`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 関連する予約情報を取得するAPI
 */
export const fetchReservationLink = (beginDate = '', endDate = '', checkinTelphone = null, isInitial = 0, page = 0, limit = 0) => {
  let params = {}
  if (beginDate !== '' && endDate !== '') {
    if (checkinTelphone) {
      // ページング:チェックイン詳細内予約紐付けポップアップのリスト
      params = {
        // @ts-ignore
        beginDate: beginDate ? dayjs(beginDate).format('YYYY-MM-DD') : undefined,
        endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
        page,
        limit,
        checkinTelphone,
        isInitial: Number(isInitial),
      }
    } else {
      params = {
        // @ts-ignore
        beginDate: beginDate ? dayjs(beginDate).format('YYYY-MM-DD') : undefined,
        endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
        page,
        limit,
      }
    }
  } else {
    params = {
      page,
      limit,
    }
  }
  return http<Aipass.GetAdminReservationResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-reservation-link`,

    params,
  })
    .then(results => results.data.reservations)
    .catch(e => console.warn(e))
}

/**
 * 予約の関連顧客を取得するAPI
 */
export const fetchReservationCustomer = (reservationId: string | null = null) => {
  const params = { reservationId }
  return http<Aipass.GetAdminReservationResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-reservation`,
    params,
  })
    .then(results => results.data.customer)
    .catch(e => console.warn(e))
}

/**
 * 予約情報を更新するAPI
 */
export const putAdminReservation = (data = {}) => {
  return http<Aipass.PutAdminReservationResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-reservation`,
    data,
  }).then(results => results.data)
}

export const putAdminReservationUser = (
  reservationId: string,
  data: {
    reservationUser: ReservationUserType
    reservationGuest: ReservationGuestType | null
  },
) => {
  return http<Aipass.PutAdminReservationUserResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-reservation-user`,

    data: { ...data, reservationId },
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 予約ステータスを更新するAPI
 */
export const putAdminReservationApprovedStatus = (data: { reservationIds: string[]; approvedStatus: ApprovedStatusType }) => {
  return http<Aipass.PutAdminReservationApprovedStatusResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-reservation-approved-status`,
    data,
  }).then(results => results.data)
}

/**
 * 予約プラグイン(TL/手間いらず/ねっぱん)加入かどうかを確認するAPI
 */
export const fetchReservationStatus = () => {
  return http<Aipass.GetAdminReservationStatusResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/reservation-plugin`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 予約情報(リンカーンAPI)取得API
 */
export const fetchReservationTlApi = () => {
  return http<Aipass.PostAdminReservationTlApiResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/admin-reservation-tl-api`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 予約情報(手間いらず)取得API
 */
export const fetchReservationTemairazuApi = () => {
  return http<Aipass.PostAdminReservationTemairazuApiResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/temairazu/temairazu-reserve-info/admin-temairazu-reserve`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 予約情報(ねっぱん)取得API
 */
export const fetchReservationNeppanApi = () => {
  const envValue = process.env.REACT_APP_ENV
  const data = { envValue }
  return http<Aipass.PostNeppanAdminNeppanReserveResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/neppan/admin-neppan-reserve`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * CSV予約情報を登録するAPI
 */
export const updateReservationCsv = (fileName: string, reservationData: ReservationData[]): Promise<AxiosResponse<void>> => {
  const data = { fileName, reservationData, version: 3 }
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/admin-reservation-csv`,
    data,
  })
}

/**
 * CSV予約情報を登録するAPI
 */
export const insertTicketCsv = (
  fileName: string,
  ticketData: { reservationId: string | null; exchangeCode: string | null; ticketName: string | null }[],
): Promise<AxiosResponse<void>> => {
  const data = { fileName, ticketData }
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/admin-ticket-csv`,
    data,
  })
}

/**
 * 客室情報を取得するAPI
 */
export const fetchGuestRoomForHotel = () => {
  return http<Aipass.GetAdminGuestRoomResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-guest-room`,
  })
    .then(results => results.data.guestRoomInfo)
    .catch(e => console.warn(e))
}

/**
 * 客室,鍵情報を取得するAPI
 */
export const fetchGuestRoomKey = () => {
  return http<Aipass.GetAdminGuestRoomKeyResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/smart-lock/assign-key/rooms`,
  })
    .then(results => results.data)
    .catch(e => {
      console.warn(e)
      return []
    })
}

/**
 * 鍵情報を更新するAPI
 */
export const updateGuestRoomKey = (guestRoomKeys: ListType[]) => {
  return http<Aipass.PutAdminGuestRoomKeyResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/smart-lock/assign-key/rooms`,
    data: { guestRoomKeys },
  }).catch(e => console.warn(e))
}

/**
 * チェックイン情報の客室情報を取得するAPI
 */
export const fetchGuestRoomForFee = ({ reservationId = '', checkinId = '', hotelId = '' }) => {
  let params
  if (reservationId) {
    params = {
      reservationId,
    }
  }
  if (checkinId) {
    params = {
      checkinId,
    }
  }

  if (hotelId) params['hotelId'] = hotelId

  return http<Aipass.GetGuestRoomAssignResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/guest-room-assign`,
    params,
  })
    .then(results => results.data.rooms)
    .catch(e => console.warn(e))
}

/**
 * 予約情報の客室情報を取得するAPI
 */
export const fetchGuestRoomForReservation = (reservationId, hotelId) => {
  const params = {
    reservationId,
    hotelId,
  }

  return http<Aipass.GetGuestRoomAssignResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/guest-room-assign`,
    params,
  })
    .then(results => results.data.rooms)
    .catch(e => console.warn(e))
}

export const fetchGuestRoomIndicator = (date: Dayjs) => {
  return http<GuestRoomIndicator.RoomIndicatorResponseType>({
    method: 'get',
    url: `${env('BASE_API_V3')}/room-manager/indicator`,
    params: { targetDate: date.format('YYYY-MM-DD') },
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * GET guest-room
 * 滞在中以外の部屋タイプと部屋番号の取得(客室管理プラグイン無)
 * @param checkinId
 */
export const fetchGuestRoomSelectable = () => {
  return http<Aipass.GetGuestRoomSelectableResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/guest-room-selectable`,
  })
    .then(results => results.data.guestRooms)
    .catch(e => console.warn(e))
}

/**
 * GET guest-room
 * 滞在中以外の部屋タイプと部屋番号の取得(客室管理プラグイン有)
 * @param checkinId
 */
export const fetchGuestRoomSelectableHasRoomManagerPlugin = (
  assignCheckinDate: string,
  assignCheckoutDate: string,
  reservationId?: string,
) => {
  const params: { assignCheckinDate: string; assignCheckoutDate: string; reservationId?: string } = {
    assignCheckinDate: dayjs(assignCheckinDate).hour(12).format('YYYY-MM-DD'),
    assignCheckoutDate: dayjs(assignCheckoutDate).hour(12).format('YYYY-MM-DD'),
  }

  if (reservationId) {
    params.reservationId = reservationId
  }

  return http<Aipass.GetRoomManagerGuestRoomSelectableResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/room-manager/guest-room-selectable`,
    params,
  })
    .then(results => results.data.guestRooms)
    .catch(e => console.warn(e))
}

/**
 * 客室管理 全部屋アサイン状況取得API
 * @param data
 */
export const fetchGuestRoomAssignStatus = (type, beginDate = '', endDate = '') => {
  const params = {
    type,
    beginDate: beginDate ? dayjs(beginDate).format('YYYY-MM-DD') : undefined,
    endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
    with_unspecified: 1,
  }
  return http<Aipass.GetRoomManagerGuestRoomAssignStatusResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/room-manager/guest-room-assign-status`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const fetchAdminReservationStock = (dateFrom, dateTo) => {
  return http<{
    stocks: SiteControllerStockType.SiteControllerStockType
    totalRemainingCountEachDate: SiteControllerStockType.TotalRemainingCountEachDateType
  }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-reservation-stock`,
    headers: { Authorization: getIdToken() },
    params: { dateFrom, dateTo },
  }).then(results => results.data)
}

/**
 * 客室情報を登録するAPI
 */
export const updateGuestRoom = (guestRoomTypes, guestRoomOtaCodes, guestRooms, guestRoomFloors) => {
  const data = { guestRoomTypes, guestRoomOtaCodes, guestRooms, guestRoomFloors }
  return http<Aipass.PostAdminGuestRoomResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/admin-guest-room`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 各詳細からアサイン部屋保存処理
 */

export const updateAllGuestRoomAssign = (
  reservation: AdminReservationResponseType['reservations'],
  editGuestRooms: GuestRoomAssignForFreeResponseType['rooms'],
) => {
  const data = {
    reservationId: reservation.reservationId,
    checkinDate: dayjs(reservation.checkinDate).hour(12).format('YYYY-MM-DD'),
    checkoutDate: reservation.checkoutDate ? dayjs(reservation.checkoutDate).hour(12).format('YYYY-MM-DD') : null,
    rooms: editGuestRooms.map(room => {
      return {
        guestRoomAssignId: room.guestRoomAssignId ?? null,
        guestRoomId: room.guestRoomId ?? null,
        assignGuestName: room.assignGuestName ?? null,
        roomTypeName: room.typeNameJa ?? null,
        roomNumber: room.roomNumber ?? null,

        paxChildA: room.paxChildA ?? null,
        paxChildB: room.paxChildB ?? null,
        paxChildC: room.paxChildC ?? null,
        paxChildD: room.paxChildD ?? null,
        paxChildE: room.paxChildE ?? null,
        paxChildF: room.paxChildF ?? null,
        paxChildOther: room.paxChildOther ?? null,
        paxFemale: room.paxFemale ?? null,
        paxMale: room.paxMale ?? null,
        pax: room.pax ?? null,
        checkinDate: room.assignCheckinDate ? dayjs(room.assignCheckinDate).hour(12).format('YYYY-MM-DD') : null,
        checkoutDate: room.assignCheckoutDate ? dayjs(room.assignCheckoutDate).hour(12).format('YYYY-MM-DD') : null,
      }
    }),
  }
  return http<Aipass.PostGuestRoomAssignResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/guest-room-assign`,
    data,
  }).then(results => results.data)
}

export const updateGuestRoomAssign = (editGuestRooms: GuestRoomAssignForFreeResponseType['rooms']) => {
  const data = {
    rooms: editGuestRooms.map(room => {
      return {
        guestRoomAssignId: room.guestRoomAssignId ?? null,
        assignGuestName: room.assignGuestName ?? null,

        paxChildA: room.paxChildA ?? null,
        paxChildB: room.paxChildB ?? null,
        paxChildC: room.paxChildC ?? null,
        paxChildD: room.paxChildD ?? null,
        paxChildE: room.paxChildE ?? null,
        paxChildF: room.paxChildF ?? null,
        paxChildOther: room.paxChildOther ?? null,
        paxFemale: room.paxFemale ?? null,
        paxMale: room.paxMale ?? null,
        pax: room.pax ?? null,
      }
    }),
  }
  return http<Aipass.PostGuestRoomAssignResponse>({
    method: 'patch',
    url: `${env('BASE_API_V3')}/guest-room-assign`,
    data,
  }).then(results => results.data)
}

/**
 * 宿泊者ゲストを削除する
 */
export const deleteGuest = (checkinId, assignGroupId = '') => {
  const params = { checkinId, assignGroupId }
  return http<Aipass.DeleteGuestRoomAssignResponse>({
    method: 'delete',
    url: `${env('BASE_API_V3')}/guest-room-assign`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * チェックインと予約データの紐付け
 */
export const insertAdminReservationCheckin = (checkinId, reservationId, checkinDate = '', checkoutDate = '') => {
  const data = {
    checkinId,
    reservationId,
    checkinDate: checkinDate === '' ? 0 : dayjs(checkinDate).unix(),
    checkoutDate: checkoutDate === '' ? 0 : dayjs(checkoutDate).unix(),
  }
  return http<Aipass.PostAdminReservationCheckinResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/admin-reservation-checkin`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * チェックインと予約データの紐付け解除
 */
export const deleteAdminReservationCheckin = (checkinId, reservationId) => {
  const params = { checkinId, reservationId }
  return http<Aipass.DeleteAdminReservationCheckinResponse>({
    method: 'delete',
    url: `${env('BASE_API_V3')}/admin-reservation-checkin`,
    params,
  })
    .then(results => results.data)
    .catch(e => {
      if (e.response?.data?.errorMessage) {
        return e.response.data
      }
      console.warn(e)
    })
}

/**
 * 引き継ぎ用の予約メモ情報を取得するAPI
 * @param reservationId
 */
export const fetchReservationPastNote = ({ customerId, checkoutDate }: { customerId: string; checkoutDate: string }) => {
  const params = { customerId, checkoutDate }
  return http<ReservationNote[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-reservation-note-past`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 予約メモ情報を取得するAPI
 * @param reservationId
 */
export const fetchReservationNote = reservationId => {
  const params = { reservationId }
  return http<{ notes: ReservationNote[] }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-reservation-note`,
    params,
  })
    .then(results => results.data.notes)
    .catch(e => {
      console.warn(e)
      return []
    })
}

/**
 * 予約メモ情報を更新するAPI
 * @param reservationId
 * @param notes
 */
export const updateReservationNote = (reservationId, notes) => {
  const data = { reservationId, notes }

  return http<Aipass.PostAdminReservationNoteResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-reservation-note`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * お知らせ情報を取得するAPI
 */
export const fetchAdminNotice = () => {
  return http<Aipass.GetNoticeResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/notice`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 通知センター表示API
 */
export const checkNoticeCenter = () => {
  return http<Aipass.PutNoticeResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/notice`,
  }).catch(e => console.warn(e))
}

/**
 * お知らせ既読API
 * @param id
 */
export const readAdminNotice = (id: string) => {
  return http<Aipass.PutNoticeResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/notice/${id}`,
  }).catch(e => console.warn(e))
}

/**
 * プラグイン加入対象かどうかの確認
 */
export const fetchPlugins = () => {
  return http<Aipass.GetPluginResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/plugin`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 客室管理プラグインの部屋アサインAPI
 */
export const putRoomManagerPluginGuestRoomAssign = ({
  checkinDate,
  checkoutDate,
  editGuestRooms,
  reservationTel,
  reservationName,
  reservationId,
  roomsInPreparation,
  excludeStockCheck,
  validationOnly,
}: {
  checkinDate: moment.Moment
  checkoutDate: moment.Moment
  editGuestRooms: GuestRoomAssignResponseType['rooms']
  reservationTel: string
  reservationName: string
  reservationId?: string
  roomsInPreparation?: GuestRoomAssignResponseType['rooms']
  excludeStockCheck?: boolean
  validationOnly?: boolean
}): Promise<{ reservationId: string; reservationUuid: string }> => {
  // 部屋アサイン情報を作成
  const rooms = editGuestRooms.map(editGuestRoom => {
    return {
      guestRoomAssignId: editGuestRoom.guestRoomAssignId ?? null,
      guestRoomId: editGuestRoom.guestRoomId ?? null,
      roomTypeName: editGuestRoom.typeNameJa ?? null,
      checkinDate: editGuestRoom.assignCheckinDate ? dayjs(editGuestRoom.assignCheckinDate).format('YYYY-MM-DD') : null,
      checkoutDate: editGuestRoom.assignCheckoutDate ? dayjs(editGuestRoom.assignCheckoutDate).format('YYYY-MM-DD') : null,
    }
  })

  const data = {
    checkinDate: checkinDate.format('YYYY-MM-DD'),
    checkoutDate: checkoutDate.format('YYYY-MM-DD'),
    reservationId,
    reservationTel,
    reservationName,
    rooms,
    excludeStockCheck,
    validationOnly,
  }

  if (roomsInPreparation?.length) {
    data['roomsInPreparation'] = roomsInPreparation.map(editGuestRoom => {
      return {
        guestRoomAssignId: editGuestRoom.guestRoomAssignId ?? null,
        guestRoomId: editGuestRoom.guestRoomId ?? null,
        roomTypeName: editGuestRoom.typeNameJa ?? null,
        checkinDate: editGuestRoom.assignCheckinDate ? dayjs(editGuestRoom.assignCheckinDate).format('YYYY-MM-DD') : null,
        checkoutDate: editGuestRoom.assignCheckoutDate ? dayjs(editGuestRoom.assignCheckoutDate).format('YYYY-MM-DD') : null,
      }
    })
  }

  return http<Aipass.PutRoomManagerGuestRoomAssignResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/room-manager/guest-room-assign`,
    data,
  }).then(results => results.data)
}

/**
 * 売上管理の設定内容を取得
 */
export const fetchSalesSubject = () => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-subject`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}
export const fetchSalesSubjectReduceSubSubject = () => {
  return http<{ salesSubjects: SalesSubjectType[] }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-subject`,
  })
    .then(results => {
      const salesSubjects = results.data?.salesSubjects || []
      const subjectOfSubSubject = salesSubjects
        .filter((sub: SalesSubjectType) => sub.subSubjectCode !== '-' && sub.subSubjectCode !== '')
        .map(({ departmentId, subjectCode, subjectName }) => ({
          departmentId,
          subjectCode,
          subjectName,
        }))

      return salesSubjects
        .reduce((prev: SalesSubjectType[], current: SalesSubjectType) => {
          if (prev[prev.length - 1]?.subjectCode === current?.subjectCode && current?.subSubjectName !== '-') {
            return prev
          } else {
            return [...prev, current]
          }
        }, [])
        .map(sub => ({
          ...sub,
          hasSubSubject: subjectOfSubSubject.some(
            subSubject =>
              subSubject.departmentId === sub.departmentId &&
              subSubject.subjectCode === sub.subjectCode &&
              subSubject.subjectName === sub.subjectName,
          ),
        }))
    })
    .catch(e => {
      console.warn(e)
      return []
    })
}
export const createSalesSubject = (data: SalesSubjectFormValue) => {
  return http<void>({
    method: 'post',
    url: `${env('BASE_API_V3')}/sales-manager/sales-subject`,
    data,
  })
}
export const updateSalesSubject = (id: string, data: SalesSubjectFormValue) => {
  return http<void>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-subject/${id}`,
    data,
  })
}
export const deleteSalesSubject = (ids: string[]) => {
  return http<void>({
    method: 'delete',
    url: `${env('BASE_API_V3')}/sales-manager/sales-subject/bulk-delete`,
    params: { ids },
  })
}
export const exportSalesSubject = () => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-subject/csv`,
  }).then(results => results.data)
}
export const importSalesSubject = salesSubjectDepartmentData => {
  const data = { salesSubjectDepartmentData, version: 3 }
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-subject/csv`,
    data,
  }).then(results => results.data)
}

export const fetchSalesSubjectSet = () => {
  return http<{ salesSubjectSet: SalesSubjectSetSettingType[] }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-subject-set`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const createSalesSubjectSet = data => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/sales-manager/sales-subject-set`,
    data,
  }).then(results => results.data)
}

export const updateSalesSubjectSet = data => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-subject-set`,
    data,
  }).then(results => results.data)
}

export const deleteSalesSubjectSet = params => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/sales-manager/sales-subject-set`,
    params,
    paramsSerializer: params => {
      return qs.stringify(params)
    },
  }).then(results => results.data)
}

export const updateSalesSubjectSetList = data => {
  return http<Aipass.GetSalesManagerSalesSubjectResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-subject-set-list-csv`,
    data,
  }).then(results => results.data)
}

export const fetchSalesDepartment = () => {
  return http<SalesDepartmentMasterType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-department`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}
export const createSalesDepartment = (data: Omit<SalesDepartmentMasterType, 'id' | 'relatedSubjectMasterCount'>) => {
  return http<void>({
    method: 'post',
    url: `${env('BASE_API_V3')}/sales-manager/sales-department`,
    data,
  })
}
export const updateSalesDepartment = (id: string, data: Omit<SalesDepartmentMasterType, 'id' | 'relatedSubjectMasterCount'>) => {
  return http<void>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-department/${id}`,
    data,
  })
}
export const deleteSalesDepartment = (ids: string[]) => {
  return http<void>({
    method: 'delete',
    url: `${env('BASE_API_V3')}/sales-manager/sales-department/bulk-delete`,
    params: { ids },
  })
}

export const fetchSalesSubSubject = () => {
  return http<SalesSubSubjectMasterType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-sub-subject`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}
export const createSalesSubSubject = (data: Omit<SalesSubSubjectMasterType, 'id' | 'salesSubjectMaster'>) => {
  return http<void>({
    method: 'post',
    url: `${env('BASE_API_V3')}/sales-manager/sales-sub-subject`,
    data,
  })
}
export const updateSalesSubSubject = (id: string, data: Omit<SalesSubSubjectMasterType, 'id' | 'salesSubjectMaster'>) => {
  return http<void>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-sub-subject/${id}`,
    data,
  })
}
export const deleteSalesSubSubject = (ids: string[]) => {
  return http<void>({
    method: 'delete',
    url: `${env('BASE_API_V3')}/sales-manager/sales-sub-subject/bulk-delete`,
    params: { ids },
  })
}

export const fetchSalesAccountSubjectSet = ({
  checkinId,
  reservationId,
  salesSubjectSetId,
}: {
  checkinId?: string
  reservationId?: string
  salesSubjectSetId: string
}) => {
  const params = checkinId ? { checkinId, salesSubjectSetId } : { reservationId, salesSubjectSetId }
  return http<{ salesGroupBySalesDate: CalculatedSalesSubjectSetType[] }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-account-subject-set`,
    params,
  }).then(results => results.data)
}

/**
 * 売上管理明細の取得
 */
export const fetchSalesDetailList = (beginDate, endDate, page?, limit?, salesDailyId?) => {
  const params = {
    beginDate: beginDate === '' ? 0 : dayjs(beginDate).unix(),
    endDate: endDate === '' ? 0 : dayjs(endDate).unix(),
    page,
    limit,
    salesDailyId,
  }

  return http<Aipass.GetSalesManagerSalesResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	売上管理明細 一括精算
 */
export const adjustSales = (salesPaymentId, checkinId?, reservationId?, companyId?, salesAccountsReceivableMasterId?, saleIds?) => {
  let data: any
  if (checkinId) {
    data = { salesPaymentId, checkinId, companyId, salesAccountsReceivableMasterId }
  } else if (reservationId) {
    data = { salesPaymentId, reservationId, companyId, salesAccountsReceivableMasterId }
  } else if (saleIds) {
    data = { salesPaymentId, saleIds, companyId, salesAccountsReceivableMasterId }
  }
  return http<Aipass.PutSalesManagerSalesAdjustmentResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-adjustment`,

    data,
  }).then(results => results.data)
}

/**
 * 	売上管理の入金設定の内容を取得
 */
export const fetchSalesPayment = () => {
  return http<Aipass.GetSalesManagerSalesPaymentResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-payment`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	売上管理の入金設定の編集
 */
export const updateSalesPayment = payment => {
  const data = { payment }
  return http<Aipass.PutSalesManagerSalesPaymentResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-payment`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	売上管理のレジ設定の内容を取得
 */
export const fetchSalesCashregister = () => {
  return http<Aipass.GetSalesManagerSalesCashRegisterResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-cash-register`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	売上管理のレジ設定の編集
 */
export const updateSalesCashregister = price => {
  const data = { price }
  return http<Aipass.PutSalesManagerSalesCashRegisterResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-cash-register`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	売上の詳細を取得
 */
export const fetchSalesTotalDetail = (mode, salesManagerDailyId, salesManagerMonthId) => {
  const params = { salesManagerDailyId, salesManagerMonthId, mode }
  return http<Aipass.GetSalesManagerSalesTotalDetailResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-total-detail`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	売上管理の現金売上取得
 */
export const fetchSalesDetailCash = (mode, salesManagerDailyId, salesManagerMonthId) => {
  const params = { salesManagerDailyId, salesManagerMonthId, mode }
  return http<Aipass.GetSalesManagerSalesCashResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-cash`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 売上管理明細取得API
 */
export const fetchSales = checkinId => {
  const params = { checkinId }
  return http<Aipass.GetSalesManagerSalesResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 売上管理明細取得API
 */
export const fetchSalesReservation = reservationId => {
  const params = { reservationId }
  return http<Aipass.GetSalesManagerSalesResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	売上管理の現金売上編集
 */
export const updateSalesDetailCash = (mode, salesManagerDailyId, salesManagerMonthId, cashData) => {
  const data = { ...{ mode, salesManagerDailyId, salesManagerMonthId }, ...cashData }
  return http<Aipass.PutSalesManagerSalesCashResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-cash`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	売上管理明細登録API
 */
export const updateSales = sales => {
  const data = { sales }
  return http<Aipass.PutSalesManagerSalesResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/admin-sales`,
    data,
  }).then(results => results.data)
}

/**
 * 	売上管理の確定
 */
export const confirmSalesDetail = (mode, salesManagerDailyId, salesManagerMonthId) => {
  const data = { mode, salesManagerDailyId, salesManagerMonthId }
  return http<Aipass.PutSalesManagerSalesConfirmResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-confirm`,
    data,
  }).then(results => results.data)
}

/**
 * 	売上日報、月報の取得API
 */
export const fetchSalesReport = (mode, salesManagerDailyId, salesManagerMonthId) => {
  const params = { salesManagerDailyId, salesManagerMonthId, mode }
  return http<Aipass.GetSalesManagerSalesReportResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-report`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const fetchComExportRevCsv = (date_current_liability, start_date_period, end_date_period) => {
  const params = { date_current_liability, start_date_period, end_date_period }
  return http<Aipass.GetComExportRevCsvResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/com-export-rev-csv`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const fetchComExportCLCsv = (date_current_liability, start_date_period, end_date_period) => {
  const params = { date_current_liability, start_date_period, end_date_period }
  return http<Aipass.GetComExportCLCsvResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/com-export-cl-csv`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	売上日報、月報の作成API
 */
export const updateSalesReport = (mode, salesManagerDailyId, salesManagerMonthId, letter) => {
  const data = { mode, salesManagerDailyId, salesManagerMonthId, letter }

  return http<Aipass.PostSalesManagerSalesReportResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/sales-manager/sales-report`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	カスタムチェックインの取得API
 */
type hoge = {
  (customCheckinId: string): Promise<CustomCheckinResponseType>
  (): Promise<CustomCheckinListResponseType>
}
export const fetchCustomCheckin: hoge = (customCheckinId?: string) => {
  const params = { customCheckinId }
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/custom-checkin/admin-custom-checkin`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	カスタムチェックインの作成API
 */
export const storeCustomCheckin = data => {
  return http<Aipass.PostCustomCheckinAdminCustomCheckinResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/custom-checkin/admin-custom-checkin`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	カスタムチェックインの編集API
 */
export const updateCustomCheckin = data => {
  return http<Aipass.PutCustomCheckinAdminCustomCheckinResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/custom-checkin/admin-custom-checkin`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * カスタムチェックインの削除API
 */
export const deleteCustomCheckin = customCheckinId => {
  const params = { customCheckinId }
  return http<Aipass.DeleteCustomCheckinAdminCustomCheckinResponse>({
    method: 'delete',
    url: `${env('BASE_API_V3')}/custom-checkin/admin-custom-checkin`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	カスタムチェックインの公開状況更新API
 */
export const updateViewStatus = (data: { customCheckinId: string; viewStatus: number }) => {
  return http<Aipass.PutCustomCheckinAdminCustomCheckinStatusResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/custom-checkin/admin-custom-checkin-status`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	利用明細領収書CSVデータの取得API
 */
export const fetchCheckinSalesDetailCsv = checkinId => {
  const params = { checkinId }
  return http<Aipass.GetSalesManagerSalesCsvResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-csv`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	利用明細領収書PDFデータの取得API
 */
export const fetchCheckinSalesDetailPdf = (params: {
  checkinId?: string
  reservationId?: string
  guestName: string
  provision: string
}) => {
  return http<Aipass.GetSalesManagerSalesPdfResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-pdf`,
    params,
    responseType: 'blob',
  })
    .then(results => {
      return { data: results.data, fileName: results.headers['x-file-name'] }
    })
    .catch(e => {
      console.warn(e)
      return { data: '', fileName: '' }
    })
}

export const fetchCheckinSalesSplitDetailPdf = params => {
  return http<Aipass.GetSalesManagerSalesPdfResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-split-pdf`,
    params,
    responseType: 'blob',
    paramsSerializer: params => {
      return qs.stringify(params)
    },
  }).then(results => {
    return { data: results.data, fileName: results.headers['x-file-name'] }
  })
}

/*
 * 	宿泊管理CSVデータの取得API
 */
export const fetchAccommodationManagementCsv = data => {
  return http<Aipass.GetAdminAccommodationManagementCsvResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/accommodation-management-download-csv-sqs`,
    data: {
      ...data,
      searchBeginDate: data.searchBeginDate ? dayjs(data.searchBeginDate).unix() : 0,
      searchEndDate: data.searchEndDate ? dayjs(data.searchEndDate).unix() : 0,
    },
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/*
 * 	CSVアップロード履歴の取得API
 */
export const fetchUploadCsvHistory = () => {
  const params = {}
  return http<CsvUploadHistoryResponseType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-csv-upload-history`,
    params,
  }).then(results => results.data)
}

/*
 * 	宿泊管理CSVダウンロード履歴の取得API
 */
export const fetchDownloadCsvHistory = () => {
  const params = {}
  return http<Aipass.GetAccommodationManagementDownloadCsvHistoryResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/accommodation-management-download-csv-history`,
    params,
  })
    .then(results => results.data.DownloadCsvHistorys)
    .catch(e => console.warn(e))
}

export const fetchReceiptHistory = ({
  customerId,
  checkinId,
  reservationId,
  fileName,
  searchBeginDate,
  searchEndDate,
}: {
  customerId?: string | null
  checkinId?: string | null
  reservationId?: string | null
  fileName?: string
  searchBeginDate?: number
  searchEndDate?: number
}) => {
  const params = {}

  if (customerId) params['customerId'] = customerId
  if (reservationId) params['reservationId'] = reservationId
  if (checkinId) params['checkinId'] = checkinId
  if (fileName) params['filename'] = fileName
  if (searchBeginDate) params['searchBeginDate'] = searchBeginDate
  if (searchEndDate) params['searchEndDate'] = searchEndDate

  return http<Aipass.GetAccommodationManagementDownloadCsvHistoryResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-pdf-history`,
    params,
  })
    .then(results => results.data?.history || [])
    .catch(e => console.warn(e))
}

/**
 * 領収書履歴の有効可否の情報更新API
 */
// PutReceiptHistoryResponse
export const updateReceiptHistory = (uploadReceiptLogsId, isEnabled) => {
  const data = { uploadReceiptLogsId, isEnabled }
  return http<Aipass.PutReceiptHistoryResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-pdf-history`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/*
 * 	売上管理CSVデータの取得API
 */
export const fetchSalesDetailCsv = (beginDate = '', endDate = '', salesDailyId?) => {
  const params = {
    beginDate: beginDate === '' ? 0 : dayjs(beginDate).unix(),
    endDate: endDate === '' ? 0 : dayjs(endDate).unix(),
    salesDailyId,
  }
  return http<Aipass.GetSalesManagerSalesDetailListCsvResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-detail-list-csv`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 入金一覧CSVデータの取得API
 */
export const fetchSalePaymentListCsv = (beginDate: string | undefined, endDate: string | undefined, salesManagerMonthId?) => {
  const params = {
    beginDate: dayjs(beginDate).unix(),
    endDate: dayjs(endDate).unix(),
    salesManagerMonthId,
  }
  return http<Aipass.GetSalesManagerPaymentListCsvResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-payment-list-csv`,
    params,
  })
    .then((results: AxiosResponse<Aipass.GetSalesManagerPaymentListCsvResponse>) => {
      return results.data
    })
    .catch(e => console.warn(e))
}

/**
 * カスタムチェックインの設定内容 取得API
 */
export const fetchGuide = hotelId => {
  const params = {
    hotelId,
  }
  return http<Aipass.GetCustomCheckinCustomCheckinResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/custom-checkin/custom-checkin`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * チェックイン詳細のゲスト情報追加API
 */
export const updateGuestInfo = (checkinId, checkin) => {
  const data = { checkinId, checkin }

  return http<Aipass.PutCheckinApprovedResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/hotel/admin-checkin-approved`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * ゲストチェックインのゲスト情報追加API
 */
export const updateSelfCheckin = (checkinId: string, checkin: object, reservationId: string | null = null) => {
  const data = {
    checkinId,
    checkin,
    isAdminControl: true,
    reservationIds: reservationId ? [reservationId] : [],
  }

  return http<Aipass.PostCheckinApprovedResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/hotel/admin-checkin-approved`,
    data,
  }).then(results => results.data)
}

export const updateGuestInfoWithoutHotel = (checkinId, checkin) => {
  const data = { checkinId, checkin }

  return http<Aipass.PutCheckinApprovedResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/checkin-approved`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/*
 * 	同じグループの代表者、同行者に1人でもチェックインリストにいるかどうか
 */
export const fetchIsInSameGroupMemberCheckin = checkinId => {
  const params = { checkinId }
  return http<Aipass.GetAdminGroupMemberResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-checkin-group-member`,

    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/*
 * 	売上管理の割引設定の内容を取得
 */
export const fetchSalesDiscount = () => {
  return http<Aipass.GetSalesManagerSalesDiscountResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-discount`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 	売上管理の割引設定の編集
 */
export const updateSalesDiscount = discount => {
  const data = { discount }
  return http<Aipass.PutSalesManagerSalesDiscountResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-discount`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/*
 * 	チェックイン可能な予約を取得するAPI
 */
export const fetchAdminReservationForCheckin = (beginDate, endDate, searchText, page, limit = 30, reservationIds) => {
  const params = {
    beginDate: beginDate ? dayjs(beginDate).format('YYYY-MM-DD') : undefined,
    endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
    searchText,
    page,
    limit,
    reservationIds,
  }

  return http<Aipass.GetAdminReservationForCheckinResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-reservation-for-checkin`,

    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 売上管理割引取得API
 */
export const fetchDiscount = checkinId => {
  const params = { checkinId, type: 'discount' }
  return http<Aipass.GetSalesManagerSalesResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 売上管理割引取得API
 */
export const fetchReservationDiscount = reservationId => {
  const params = { reservationId, type: 'discount' }
  return http<Aipass.GetSalesManagerSalesResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 予約者の確定済み明細取得API
 */
export const fetchReservationConfirmedSales = reservationId => {
  const params = { reservationId, type: 'confirmedSales' }
  return http<Aipass.GetSalesManagerSalesResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * アイコン表示非表示 取得API
 */
export const fetchIconDisplay = hotelId => {
  const params = { hotelId }
  return http<Aipass.GetIconDisplayResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/icon-display`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * アイコン表示非表示 更新API
 */
export const updateIconStatus = iconDisplay => {
  const data = { iconDisplay }
  return http<Aipass.PutIconDisplayResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-icon-display`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 全体掲示板 取得API
 */
export const fetchDashboardNote = () => {
  return http<Aipass.GetDashboardNoteResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/dashboard-note`,
  })
    .then(results => results.data.notes)
    .catch(e => console.warn(e))
}

/**
  全体掲示板 更新API
 */
export const updateDashboardNote = (noteId = '', memo) => {
  const data = { noteId, memo }
  return http<Aipass.PutDashboardNoteResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/dashboard-note`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * セルフチェックイン予約確認API
 */
export const selfCheckin = (hotelId, checkinCode, guestTelephone, type) => {
  const params = { checkinCode, guestTelephone, hotelId, type }
  return http<Aipass.GetSelfCheckinSelfCheckinResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/self-checkin/self-checkin`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * セルフチェックインでの館内案内情報 基本情報取得API
 */
export const fetchFacilityBasicInfoInSelfCheckin = hotelId => {
  const params = { hotelId: hotelId }
  return http<Aipass.GetSelfCheckinSelfCheckinBasicInfoResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/self-checkin/self-checkin-basic-info`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const fetchMobileRequestProducts = () => {
  return http<MobileRequest.ProductType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/mobile-request/admin-products`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}
export const postMobileRequestProducts = (data: MobileRequest.ProductInputType) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/mobile-request/admin-products`,
    data,
  })
}
export const putMobileRequestProducts = (id: string, data: MobileRequest.ProductInputType) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/mobile-request/admin-products/${id}`,
    data,
  })
}
export const deleteMobileRequestProducts = (ids: string[]) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/mobile-request/admin-products`,
    params: { ids },
  })
}

export const fetchMobileRequestCategories = () => {
  return http<MobileRequest.CategoryType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/mobile-request/admin-categories`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}
export const postMobileRequestCategories = (data: MobileRequest.CategoryInputType) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/mobile-request/admin-categories`,
    data,
  })
}
export const putMobileRequestCategories = (id: number, data: MobileRequest.CategoryInputType) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/mobile-request/admin-categories/${id}`,
    data,
  })
}
export const deleteMobileRequestCategories = (ids: number[]) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/mobile-request/admin-categories`,
    params: { ids },
  })
}

export const fetchMobileRequestTags = () => {
  return http<MobileRequest.TagType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/mobile-request/admin-tags`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}
export const postMobileRequestTags = (data: MobileRequest.TagInputType) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/mobile-request/admin-tags`,
    data,
  })
}
export const putMobileRequestTags = (id: number, data: MobileRequest.TagInputType) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/mobile-request/admin-tags/${id}`,
    data,
  })
}
export const deleteMobileRequestTags = (ids: number[]) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/mobile-request/admin-tags`,
    params: { ids },
  })
}

export const fetchMobileRequestSalesHours = () => {
  return http<MobileRequest.SalesHourType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/mobile-request/admin-sales-hours`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}
export const postMobileRequestSalesHours = (data: MobileRequest.SalesHourInputType) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/mobile-request/admin-sales-hours`,
    data,
  })
}
export const putMobileRequestSalesHours = (id: number, data: MobileRequest.SalesHourInputType) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/mobile-request/admin-sales-hours/${id}`,
    data,
  })
}
export const deleteMobileRequestSalesHours = (ids: number[]) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/mobile-request/admin-sales-hours`,
    params: { ids },
  })
}

export const fetchSalesDepartmentForRequest = () => {
  return http<{
    departments: Array<MobileRequest.SalesDepartmentType>
  }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/mobile-request/admin-sales-departments`,
  })
    .then(results => results.data.departments)
    .catch(e => console.warn(e))
}

export const fetchSalesSubjectForProduct = departmentId => {
  const params = { departmentId }
  return http<{ subjects: MobileRequest.SalesSubjectType[] }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/mobile-request/admin-sales-subjects`,
    params,
  })
    .then(results => results.data.subjects)
    .catch(e => console.warn(e))
}

export const updateMobileRequestEmailOrder = (params: MobileRequest.NotificationInputType) => {
  return http<Aipass.PutSettingEmailOrder>({
    method: 'put',
    url: `${env('BASE_API_V3')}/mobile-request/admin-email`,
    params,
  })
}

/**
 * モバイルリクエスト リクエスト一覧取得
 */
export const fetchOrders = (beginDate, endDate, page, limit) => {
  const params = {
    beginDate: beginDate === '' ? 0 : dayjs(beginDate).unix(),
    endDate: endDate === '' ? 0 : dayjs(endDate).unix(),
    page,
    limit,
  }
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/mobile-request/admin-order`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * モバイルリクエスト ダッシュボード用リクエスト一覧取得
 */
export const fetchDashboardOrders = () => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/mobile-request/admin-order`,
  })
    .then(results => results.data.orders)
    .catch(e => console.warn(e))
}

/**
 * モバイルリクエスト リクエスト一覧詳細取得
 */
export const fetchUserOrders = checkinId => {
  const params = { checkinId }
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/mobile-request/admin-order-detail`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * モバイルリクエスト ステータス更新
 */
export const updateOrderStatus = (orderId: string | string[], status, checkinId?) => {
  const data = {
    orderId: typeof orderId === 'string' ? orderId : null,
    orderIds: typeof orderId !== 'string' ? orderId : null,
    status,
    checkinId,
  }
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/mobile-request/admin-order-detail`,
    data,
  }).then(results => results.data)
}

/**
 * セルフチェックインで部屋メール用情報取得、
 *
 */
export const assignedRoomByCheckinId = (hotelId, checkinIds) => {
  const params = { hotelId, checkinIds }
  return http<Aipass.GetSelfCheckinAssignedRoomByCheckinIdResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/self-checkin/self-checkin-assigned-room-by-checkin-id`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 予約新規作成
 */
export const createReservation = (checkinDate, checkoutDate) => {
  const data = {
    checkinDate: dayjs(checkinDate).add(12, 'h').format('YYYY-MM-DD'),
    checkoutDate: dayjs(checkoutDate).add(12, 'h').format('YYYY-MM-DD'),
  }
  return http<Aipass.PostAdminReservationResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/admin-reservation`,
    data,
  }).then(results => results.data)
}

/**
 * 帳票 取得API
 */
export const fetchBusinessForms = (beginDate, endDate, numberOfRooms) => {
  const params = {
    beginDate: dayjs(beginDate).format('YYYY-MM-DD'),
    endDate: dayjs(endDate).format('YYYY-MM-DD'),
    numberOfRooms,
  }
  return http<Aipass.GetSalesManagerBusinessFormsResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-business-forms`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 帳票日別CSV 取得API
 */
export const fetchBusinessFormsCsv = (beginDate = '', endDate = '', numberOfRooms = '', mode = 'daily') => {
  const params = {
    beginDate: dayjs(beginDate).format('YYYY-MM-DD'),
    endDate: dayjs(endDate).format('YYYY-MM-DD'),
    numberOfRooms,
    mode,
  }
  return http<Aipass.GetSalesManagerBusinessFormsCsvResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-business-forms-csv`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * RemoteLock
 * クライアント情報を取得
 */
export const getClientInfo = () => {
  return http<Aipass.GetRemoteLockClientInfoResponse>({
    method: 'get',
    url: `${env('BASE_API')}/remote-lock/client-info`,
  }).then(results => results.data)
}

/**
 * RemoteLock
 * 連携方法の更新（連携フラグの更新）
 */
export const postClientCoordination = (approvalCode: String) => {
  const data = { approvalCode }
  return http<Aipass.PostRemoteLockClientCoordinationResponse>({
    method: 'post',
    url: `${env('BASE_API')}/remote-lock/client-coordination/token`,

    data,
  }).then(results => results.data)
}

/**
 * RemoteLock
 * ゲストルームのデバイスを最新に更新する
 */
export const remoteLockSyncDevices = () => {
  return http<Aipass.PutRemoteLockRemoteLockDeviceResponse>({
    method: 'put',
    url: `${env('BASE_API')}/remote-lock/devices/sync`,
  }).then(results => results.data)
}

/**
 * RemoteLock
 * アカウントとクライアントとの連携を解除する
 */
export const cancelClientCoordination = () => {
  return http<Aipass.PutRemoteLockClientCoordinationResponse>({
    method: 'put',
    url: `${env('BASE_API')}/remote-lock/client-coordination/revoke`,
  }).then(results => results.data)
}

/**
 * 機器の設定情報取得API
 */
export const fetchDeviceSetting = () => {
  return http<deviceSettingResponseType>({
    method: 'get',
    url: `${env('BASE_API_V3')}/device-setting`,
  })
    .then(results => results.data.deviceSettings)
    .catch(e => console.warn(e))
}

/**
 * セルフチェックイン設定情報取得API
 */
export const fetchSelfCheckinSetting = hotelId => {
  const params = { hotelId }
  return http<{
    self_checkin_required_identify: Array<{
      requiredAccompanyNameInput: boolean
      requiredAddressInput: boolean
      requiredBirthDateInput: boolean
      requiredEmailInput: boolean
      requiredForeignIdentify: boolean
      requiredFuriganaInput: boolean
      requiredGenderInput: boolean
      requiredIdentify: boolean
      requiredTelephoneInput: boolean
      isFrontDescGuidance: boolean
    }>
  }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/self-checkin/self-checkin-setting`,
    params,
  })
    .then(results => results.data?.self_checkin_required_identify?.[0])
    .catch(e => console.warn(e))
}

/**
 * セルフチェックイン設定情報更新API
 */
export const putSelfCheckinSetting = param => {
  const data = param
  return http<Aipass.PutSelfCheckinSettingResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/self-checkin/self-checkin-setting`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 顧客一覧取得API
 */
export const fetchCustomers = (name, nameKana, beginDate, endDate, page, limit) => {
  const params = {
    name,
    nameKana,
    beginDate: beginDate ? dayjs(beginDate).format('YYYY-MM-DD') : undefined,
    endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : undefined,
    page,
    limit,
  }
  return http<Aipass.FetchCustomersResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/customer-list`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 客室番号設定
 * 客室番号の一覧を取得
 */
export const getAdminGuestRoomNumber = () => {
  return http<GuestRoomNumberType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-guest-room-number`,
  })
    .then(results => results.data)
    .catch(e => {
      console.warn(e)
      return []
    })
}

/**
 * 顧客詳細取得API
 */
export const fetchCustomerInfo = hotelCuicinUserId => {
  const params = { hotelCuicinUserId }
  return http<Aipass.FetchCustomerInfoResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/customer-info`,
    params,
  })
    .then(results => results.data.hotelCuicinUser)
    .catch(e => console.warn(e))
}

/**
 * 顧客情報編集API
 */
export const updateCustomerInfo = customer => {
  const data = {
    checkin: {
      name: customer.name,
      nameKana: customer.nameKana,
      birthDate: customer.birthDate,
      nationality: customer.nationality,
      gender: customer.gender,
      postalCode: customer.postalCode,
      address: customer.address,
      telephone: customer.telephone,
      email: customer.email,
      occupation: customer.occupation,
      otherOccupation: customer.otherOccupation,
      picture: customer.picture,
      company: customer.company,
      hotelCuicinUserId: customer.hotelCuicinUserId,
    },
  }

  return http<Aipass.PutCustomerInfoResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/customer-info`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const updateCustomerTag = (customer, tag) => {
  const data = {
    tag: tag || '',
  }

  return http<Aipass.PutCustomerInfoResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/customer-tag/${customer.hotelCuicinUserId}`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 顧客検索取得API
 */
export const fetchCustomerSearch = (name = '', nameKana = '', guestTelephone = '', userTelephone = '', page, limit) => {
  const params = {
    name,
    nameKana,
    guestTelephone,
    userTelephone,
    page,
    limit,
  }

  return http<Aipass.FetchCustomersResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/customer-search`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 客室番号設定
 * 客室番号の追加
 */
export const insertAdminGuestRoomNumber = (data: {
  displayOrder: string
  assignOrder: string
  roomNumber: string
  roomTypeId: string
  roomFloorId: string
  capacity: string | undefined
}) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/settings/guest-room`,
    data,
  })
}

/**
 * 客室番号設定
 * 客室番号の更新&削除
 */
export const putAdminGuestRoomNumber = (
  id: string,
  data: {
    displayOrder: string
    assignOrder: string
    roomNumber: string
    roomTypeId: string
    roomFloorId: string
    capacity: string | undefined
  },
) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/settings/guest-room/${id}`,
    data,
  })
}

export const deleteAdminGuestRoomNumber = (ids: string[]) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/settings/guest-room`,
    params: { ids },
  })
}

/**
 * 客室番号設定
 * 客室番号CSVでの追加
 */
export const insertAdminGuestRoomNumberCsv = guestRoomNumbers => {
  const data = { guestRoomNumbers }

  return http<Aipass.PostAdminGuestRoomNumberCsvResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/admin-guest-room-number-csv`,
    data,
  }).then(results => results.data)
}

/**
 * 客室
 * 客室タイプのセレクト一覧取得
 */
export const getAdminGuestRoomTypeSelect = () => {
  return http<GuestRoomTypeSelectType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-guest-room-type-select`,
  })
    .then(results => results.data)
    .catch(e => {
      console.warn(e)
      return []
    })
}

/**
 * 顧客紐付けAPI
 */
export const createCustomerLink = (checkinId, reservationId, hotelCuicinUserId) => {
  const data = { checkinId, reservationId, hotelCuicinUserId }
  return http<Aipass.PostCustomerLinkResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/customer-link`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 客室タイプ設定
 * 客室タイプの一覧を取得
 */
export const getAdminGuestRoomType = () => {
  return http<Aipass.GetAdminGuestRoomTypeResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-guest-room-type`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 客室タイプ設定
 * 客室タイプの追加
 */
export const insertAdminGuestRoomType = (data: NewGuestRoomTypeType) => {
  return http<Aipass.PostAdminGuestRoomTypeResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/admin-guest-room-type`,

    data,
  }).then(results => results.data)
}

/**
 * 客室タイプ設定
 * 客室タイプの更新
 */
export const putAdminGuestRoomType = (id: string, data: NewGuestRoomTypeType) => {
  return http<Aipass.PutAdminGuestRoomTypeResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-guest-room-type`,
    data,
  }).then(results => results.data)
}

/**
 * 客室タイプ設定
 * 客室タイプの削除
 */
export const deleteAdminGuestRoomType = (id: string[]) => {
  return http<Aipass.PutAdminGuestRoomTypeResponse>({
    method: 'delete',
    url: `${env('BASE_API_V3')}/admin-guest-room-type`,
    params: { id },
  }).then(results => results.data)
}

/**
 * 顧客紐付け解除API
 */
export const deleteCustomerLink = (checkinId, reservationId) => {
  const params = { checkinId, reservationId }
  return http<Aipass.PostAdminCheckinIdentificationResponse>({
    method: 'delete',
    url: `${env('BASE_API_V3')}/customer-link`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 顧客履歴一覧取得API
 */
export const fetchCustomerHistory = hotelCuicinUserId => {
  const params = { hotelCuicinUserId }
  return http<Aipass.FetchCustomerHistoryResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/customer-history`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 客室タイプ設定
 * 客室タイプCSVでの追加
 */
export const insertAdminGuestRoomTypeCsv = guestRoomTypes => {
  const data = { guestRoomTypes }

  return http<Aipass.PostAdminGuestRoomTypeCsvResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/admin-guest-room-type-csv`,
    data,
  }).then(results => results.data)
}

/**
 * 客室フロア設定
 * 客室フロアの一覧取得
 */
export const getAdminGuestRoomFloor = () => {
  return http<GuestRoomFloorType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/admin-guest-room-floor`,
  })
    .then(results => results.data)
    .catch(e => {
      console.warn(e)
      return []
    })
}

/**
 * 客室フロア設定
 * 客室フロアの追加
 */
export const insertAdminGuestRoomFloor = (data: { displayOrder: string | undefined; roomFloorName: string }) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/admin-guest-room-floor`,
    data,
  })
}

/**
 * 客室フロア設定
 * 客室フロアの更新&削除
 */
export const putAdminGuestRoomFloor = (id: string, data: { displayOrder: string | undefined; roomFloorName: string }) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-guest-room-floor/${id}`,
    data,
  })
}

export const deleteAdminGuestRoomFloor = (params: { ids: string[] }) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/admin-guest-room-floor`,
    params,
  })
}

/**
 * チェックイン受付 更新API
 */
export const updateCheckinReception = (checkinIds, approvedStatus: number, mode) => {
  const data = { checkinIds, approvedStatus, mode }

  return http<Aipass.PutCheckinReceptionResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-checkin-reception`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 清掃ステータス付け客室一覧 取得API
 */
export const fetchListRoom = selectedDate => {
  return http<Aipass.FetchGuestRoomForCleaningManager>({
    method: 'get',
    url: `${env('BASE_API_V3')}/cleaning-manager/guest-rooms`,
    params: { dateOf: selectedDate },
  }).then(results => results.data)
}
export const fetchListRoomAsPdf = (selectedDate: string) => {
  return http<{ url: string }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/cleaning-manager/guest-rooms/report/pdf`,
    params: { dateOf: selectedDate },
  }).then(results => results.data?.url)
}

/**
 * 清掃ステータス付け客室詳細 取得API
 */
export const fetchRoomDetail = (id, selectedDate) => {
  return http<Aipass.FetchGuestRoomDetailForCleaningManager>({
    method: 'get',
    url: `${env('BASE_API_V3')}/cleaning-manager/guest-room-detail`,
    params: { dateOf: selectedDate, guestRoomId: id },
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const fetchCleaningManagerSetting = () => {
  return http<CLeaningManager.CheckListType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/cleaning-manager/setting`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}
export const postCleaningManagerSetting = (data: CLeaningManager.CheckListFormType) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/cleaning-manager/setting`,
    data,
  })
}
export const putCleaningManagerSetting = (id: number, data: CLeaningManager.CheckListFormType) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/cleaning-manager/setting/${id}`,
    data,
  })
}
export const deleteCleaningManagerSetting = (id: number[]) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/cleaning-manager/setting`,
    params: { id },
  })
}
export const putCleaningRoomCheckList = (data: { guestRoomId: string; dateOf: string; checkListId: number; isChecked: boolean }) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/cleaning-manager/guest-room/check-item`,
    data,
  })
}

/**
 * 予約連携設定
 * マーケットセグメント取得
 */
export const fetchReservationMarketSegment = () => {
  return http<Aipass.GetAdminGuestRoomFloorResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/reservation-market-segment`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 清掃ステータス 更新API
 */
export type UpdateGuestRoomStatusForCleaningManagerRequestType = {
  guestRoomId: string
  dateOf: Dayjs
  staffReport: string | undefined
  instruction?: string
  cleaningStatus: CleaningStatusType | undefined
  roomUsageStatus: RoomUsageStatusType | undefined
  images?: Array<{ path: string }>
}
export const updateGuestRoomStatusForCleaningManager = (input: UpdateGuestRoomStatusForCleaningManagerRequestType) => {
  return http<Aipass.UpdateGuestRoomDetailStatusForCleaningManager>({
    method: 'put',
    url: `${env('BASE_API_V3')}/cleaning-manager/guest-room-status`,
    data: {
      ...input,
      dateOf: input.dateOf.format('YYYY-MM-DD'),
    },
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 全体共有内容 更新API
 */
export const updateAnnounceForCleaningManager = (dateOf: Dayjs, announce: string) => {
  return http<Aipass.UpdateAnnounceForCleaningManager>({
    method: 'post',
    url: `${env('BASE_API_V3')}/cleaning-manager/announce`,
    data: { dateOf: dateOf.format('YYYY-MM-DD'), announce },
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const bulkUpdateGuestRoomStatusForCleaningManager = (data: { from: CleaningStatusType; to: CleaningStatusType; dateOf: Dayjs }) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/cleaning-manager/bulk-guest-room-status`,
    data: {
      ...data,
      dateOf: data.dateOf.format('YYYY-MM-DD'),
    },
  })
}

/**
 * 売掛先 取得API
 */
export const fetchSalesAccountsReceivable = () => {
  return http<Aipass.FetchSalesManagerSalesAccountsReceivableResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-accounts-receivable`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * Sun api update market segment for reservation
 */
export const updateAdminReservationMarketSegment = (reservationId, marketSegmentId) => {
  const data = { reservationId, marketSegmentId }

  return http<Aipass.PutAdminReservationMarketSegment>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-reservation-market-segment`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 売掛先 更新API
 */
export const updateSalesAccountsReceivable = ({ accountsReceivables, accountsReceivablesToDelete }) => {
  return http<Aipass.PutSalesManagerSalesAccountsReceivableResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-accounts-receivable-master`,
    data: { accountsReceivables, accountsReceivablesToDelete },
  }).then(results => results.data)
}

/**
 * Sun api update market segment for reservation
 */
export const updateAdminReservationChannelCode = (reservationId, channelCodeId) => {
  const data = { reservationId, channelCodeId }

  return http<Aipass.PutAdminReservationChannelCode>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-reservation-channel-code`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}
/*
 * 予約連携設定
 * マーケットセグメント更新
 */
export const updateReservationMarketSegment = marketSegments => {
  const data = { marketSegments }

  return http<Aipass.PutCheckinReceptionResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/reservation-market-segment`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 前受金フラグを付与
 */
export const updateSalesAdvancePayment = reservationIds => {
  const data = { reservationIds }
  return http<Aipass.PutSalesAccountsReceivableResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-advance-payment`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * Sun api fetch room prices
 */
export const fetchReservationRoomPrice = reservationId => {
  const params = { reservationId }

  return http<Aipass.GetReservationRoomPriceResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/reservation-room-price`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 予約連携設定
 * チャネルコード取得
 */
export const fetchReservationChannelCode = () => {
  return http<Aipass.GetAdminGuestRoomFloorResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/reservation-channel-code`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 前受金フラグを解除
 */
export const deleteSalesAdvancePayment = reservationId => {
  const params = { reservationId }
  return http<Aipass.DeleteSalesAccountsReceivableResponse>({
    method: 'delete',
    url: `${env('BASE_API_V3')}/sales-manager/sales-advance-payment`,

    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/*
 * 予約連携設定
 * マーケットセグメント更新
 */
export const updateReservationChannelCode = channelCodes => {
  const data = { channelCodes }

  return http<Aipass.PutCheckinReceptionResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/reservation-channel-code`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 売上修正 取得API
 */
export const fetchSalesRevise = salesManagerDailyId => {
  const params = { salesManagerDailyId }
  return http<Aipass.FetchSalesManagerSalesReviseResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-revise`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * 売上修正 更新API
 */
export const updateSalesRevise = sales => {
  const data = { sales }
  return http<Aipass.PutSalesManagerSalesReviseResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/sales-manager/sales-revise`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const backupReceiptPDF = (checkinId, guestName, provision) => {
  const data = { checkinId, guestName, provision }
  return http<Aipass.BackupReceiptPDF>({
    method: 'post',
    url: `${env('BASE_API_V3')}/sales-manager/backup-receipt-pdf`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const exportBookingData = exportDate => {
  const data = { export_date: exportDate }
  return http<Aipass.GetComExportRevCsvResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/mark/booking-data`,
    data,
  }).then(results => results.data)
}

export const exportAccommodationData = exportDate => {
  const data = { export_date: exportDate }
  return http<Aipass.GetComExportRevCsvResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/mark/accommodation-data`,
    data,
  }).then(results => results.data)
}

export const fetchPaymentSetting = () => {
  return http<{ settings: PaymentSettingType }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/payment/setting`,
  }).then(results => results.data)
}

export const postPaymentSetting = setting => {
  return http<Aipass.PostPaymentSetting>({
    method: 'post',
    url: `${env('BASE_API_V3')}/payment/setting`,
    data: setting,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const updatePaymentSetting = setting => {
  return http<Aipass.UpdatePaymentSetting>({
    method: 'put',
    url: `${env('BASE_API_V3')}/payment/setting`,
    data: setting,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const issueExternalLinkPayment = (data: { reservationId?: string; checkinId?: string }) => {
  return http<{ amount: number; url: string }>({
    method: 'post',
    url: `${env('BASE_API_V3')}/payment/external-link-payment`,
    data,
  }).then(results => results.data)
}

export const fetchReservationsAndSales = (hotelId, reservationIds) => {
  const data = { hotelId, reservationIds }
  return http<Aipass.ReservationsAndSales>({
    method: 'post',
    url: `${env('BASE_API_V3')}/self-checkin/search-reservation`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const fetchReservationsForSelfCheckin = params => {
  return http<{
    searchReservations: Array<SearchReservationTypeWhenNonCheckIn> | Array<SearchReservationTypeWhenCheckedIn>
  }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/self-checkin/search-reservation`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const fetchCheckinReservationsAndSales = (hotelId, reservationIds: any = [], checkinId = null) => {
  return http<Aipass.ReservationsAndSales>({
    method: 'get',
    url: `${env('BASE_API_V3')}/self-checkin/checkin-reservation-sale-subject`,
    params: { hotelId, reservationIds, checkinId },
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const selfCheckinHandlePayment = (hotelId, reservationIds, cardToken: string) => {
  const data = {
    hotelId,
    reservationIds,
    cardToken,
  }
  return http<Aipass.ReservationsAndSales>({
    method: 'post',
    url: `${env('BASE_API_V3')}/self-checkin/payment`,
    data,
  }).then(results => results.data)
}

export const sendMailSelfCheckin = data => {
  return http<Aipass.SendMailSelfCheckin>({
    method: 'post',
    url: `${env('BASE_API_V3')}/self-checkin/send-mail-success`,
    data: { ...data, self_checkin_email: data.email },
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const selfCheckinForReservations = data => {
  return http<Aipass.SendMailSelfCheckin>({
    method: 'post',
    url: `${env('BASE_API_V3')}/checkin-approved`,
    data: { isSelfCheckin: true, ...data },
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const selfCheckinApprovedReservations = data => {
  return http<Aipass.SendMailSelfCheckin>({
    method: 'put',
    url: `${env('BASE_API_V3')}/reservation-approved`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const selfCheckinAddCheckinNote = data => {
  return http<Aipass.SendMailSelfCheckin>({
    method: 'post',
    url: `${env('BASE_API_V3')}/checkin-note`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const selfCheckinLinkReservations = data => {
  return http<Aipass.SendMailSelfCheckin>({
    method: 'post',
    url: `${env('BASE_API_V3')}/self-checkin/link-reservations-with-checkin`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const fetchPaymentSettingByHotelId = hotelId => {
  return http<Aipass.SendMailSelfCheckin>({
    method: 'get',
    url: `${env('BASE_API_V3')}/payment-setting-by-hotel`,
    params: { hotelId },
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const selfCheckinUploadIdentificationImages = data => {
  return http<Aipass.SendMailSelfCheckin>({
    method: 'post',
    url: `${env('BASE_API_V3')}/self-checkin/identification-images`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const selfCheckinFetchGuestRoomAssign = params => {
  return http<Aipass.SendMailSelfCheckin>({
    method: 'get',
    url: `${env('BASE_API_V3')}/self-checkin/guest-room-assign`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const selfCheckinCreateReservation = data => {
  return http<Aipass.SendMailSelfCheckin>({
    method: 'post',
    url: `${env('BASE_API_V3')}/self-checkin/reservation`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const fetchZipCode = zipcode => {
  const params = { zipcode }
  return http<Aipass.FetchZipCode>({
    method: 'get',
    url: `${env('BASE_API_V3')}/zipcode-search`,
    params,
  }).then(results => results.data)
}

export const createAdvanceMoney = data => {
  return http<Aipass.AdvanceMoney>({
    method: 'post',
    url: `${env('BASE_API_V3')}/advance-money/store`,
    data,
  }).then(results => results.data)
}

export const fetchAdvanceMoneyHistoryByCompany = (companyId, page, limit) => {
  const params = { page, limit }
  return http<Aipass.AdvanceMoneyHistory>({
    method: 'get',
    url: `${env('BASE_API_V3')}/advance-money/${companyId}/history`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const fetchAdvanceCompany = () => {
  return http<Aipass.AdvanceCompany>({
    method: 'get',

    url: `${env('BASE_API_V3')}/sales-manager/advance-companies`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const fetchAdvanceMoney = (page: number, limit: number, search: AdvanceMoneySearchType) => {
  return http<Aipass.AdvanceMoney>({
    method: 'get',
    url: `${env('BASE_API_V3')}/advance-money`,
    params: {
      page,
      limit,
      ...search,
    },
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const deleteAdvanceMoneyCompany = companyId => {
  return http<Aipass.AdvanceMoney>({
    method: 'delete',

    url: `${env('BASE_API_V3')}/advance-money/${companyId}`,
  })
    .then(results => results.data)
    .catch(e => {
      throw new Error(e.response.data.errorMessage)
    })
}

export const exportAdvanceMoneyCompanyCsv = (params: AdvanceMoneySearchType) => {
  return http<Aipass.AdvanceMoney>({
    method: 'get',
    url: `${env('BASE_API_V3')}/advance-money/export-csv`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * MiwaLock
 * クライアント情報を取得
 */
export const updateMiwaCardIssuesLogs = data => {
  return http<Aipass.UpdateMiwaCardIssuesLogs>({
    method: 'put',
    url: `${env('BASE_API_V3')}/room-manager/miwa_card_issues_logs`,
    data,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * MiwaLock
 * クライアント情報を取得
 */
export const getStatusSelectMiwaLockPlugin = () => {
  return http<Aipass.GetStatusSelectMiwaLockPlugin>({
    method: 'get',
    url: `${env('BASE_API_V3')}/miwa-app-connection/get-status-miwa-plugin`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

/**
 * MiwaLock
 * クライアント情報を取得
 */
export const putStatusSelectMiwaLockPlugin = (isSelected: number) => {
  const data = { is_selected: isSelected }
  return http<Aipass.PutStatusSelectMiwaLockPlugin>({
    method: 'put',
    url: `${env('BASE_API_V3')}/miwa-app-connection/change-status-miwa-plugin`,
    data,
  }).then(results => results.data)
}

/**
 * MiwaLock
 * クライアント情報を取得
 */
export const getMiwaAppWebsocketPort = pcId => {
  const params = { pcId }

  return http<Aipass.GetMiwaAppWebsocketPort>({
    method: 'get',
    url: `${env('BASE_API_V3')}/miwa-app-connection/get-app-port`,

    params,
  }).then(results => results.data)
}

/**
 * Dowload rev/cl csv
 */
export const fetchOldComExportRevCsv = (date_current_liability, type) => {
  const params = { date_current_liability, type }
  return http<Aipass.GetComExportRevCsvResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/com-download-csv`,
    params,
  }).then(results => results.data)
}

export const fetchSettingsCouponsList = () => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/settings/coupons`,
    headers: { Authorization: getIdToken() },
  }).then(results => results.data)
}

export const createSettingsCoupon = coupon => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/settings/coupons`,
    headers: { Authorization: getIdToken() },
    data: coupon,
  }).then(results => results.data)
}

export const updateSettingsCoupon = coupon => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/settings/coupons/${coupon.id}`,
    headers: { Authorization: getIdToken() },
    data: coupon,
  }).then(results => results.data)
}

export const deleteSettingsCoupon = (couponId: string) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/settings/coupons/${couponId}`,
    headers: { Authorization: getIdToken() },
  }).then(results => results.data)
}

export const fetchUsedCouponsList = ({ beginDate, endDate, page, limit = 50 }) => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/used-coupons`,
    headers: { Authorization: getIdToken() },
    params: {
      beginDate: beginDate ? moment(beginDate).unix() : undefined,
      endDate: endDate ? moment(endDate).unix() : undefined,
      page,
      limit,
    },
  }).then(results => results.data)
}

export const fetchReport = (data: {
  type: ReportTypes
  format: 'csv' | 'pdf'
  subjectIds?: string[]
  date?: string
  month?: string
  noteCategory?: ReservationNoteCategory
}) => {
  return http<Aipass.GetSalesManagerSalesCsvResponse>({
    method: 'post',
    url: `${env('BASE_API_V3')}/sales-manager/report`,
    data,
  }).then(results => results.data)
}

export const getReservationsBoundaryDates = () => {
  return http<Aipass.GetSalesManagerSalesCsvResponse>({
    method: 'get',
    url: `${env('BASE_API_V3')}/reservations-boundary-dates`,
  }).then(results => results.data)
}

export const adminCheckinApprovedTabletRegister = data => {
  return http<Aipass.GetSalesManagerSalesCsvResponse>({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-checkin-approved-tablet-register`,
    data,
  }).then(results => results.data)
}

export const guestRoomFix = data => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/room-manager/guest-room-fix`,
    data,
  }).then(results => results.data)
}

export const fetchAutoAssignmentSettings = () => {
  return http<GuestRoomAutoAssignSettingType>({
    method: 'get',
    url: `${env('BASE_API_V3')}/auto-assignment-settings`,
  }).then(results => results.data)
}

export const updateAutoAssignmentSettings = (data: GuestRoomAutoAssignSettingType) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/auto-assignment-settings`,
    data,
  }).then(results => results.data)
}

export const guestRoomAutoAssign = data => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/room-manager/guest-room-auto-assign`,
    data,
  }).then(results => results.data)
}

export const userGuestCopy = data => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-reservation-user-guest-copy`,
    data,
  }).then(results => results.data)
}

export const hotelCuicinUserCopy = data => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/admin-hotel-cuicin-user-copy`,
    data,
  }).then(results => results.data)
}

export const fetchSmaregiTerminals = () => {
  return http<Array<TerminalType>>({
    method: 'get',
    url: `${env('BASE_API_V3')}/smaregi/setting/terminals`,
  }).then(results => results.data)
}

export const postSmaregiTransactionsLayaways = (data: { contractId: string; storeId: string; terminalId: string; saleIds: string[] }) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/smaregi/transactions/layaways`,
    data,
  })
}

export const fetchSmaregiTransactions = (data: { transactionAt: Dayjs }) => {
  return http<Array<TransactionType>>({
    method: 'get',
    url: `${env('BASE_API_V3')}/smaregi/transactions`,
    params: { transactionDate: data.transactionAt.format('YYYY-MM-DD') },
  }).then(results => results.data)
}

export const fetchSmaregiTransactionDetail = ({
  contractId,
  transactionHeadId,
  transactionSide,
}: {
  contractId: string
  transactionHeadId: string
  transactionSide: TransactionSideType
}) => {
  return http<TransactionType>({
    method: 'get',
    url: `${env('BASE_API_V3')}/smaregi/transactions/${transactionHeadId}`,

    params: { contractId, transactionSide },
  }).then(results => results.data)
}

export const putSmaregiTransactionLinkReservation = (
  transactionId: string,
  data: {
    reservationId: string
    roomNumber: string
  },
) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/smaregi/transactions/${transactionId}/link-reservation`,
    data,
  })
}

export const getEmployeeHotels = () => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/employee-hotels`,
  }).then(results => results.data)
}

export const setEmployeeCurrentHotel = (currentHotelId: string) => {
  return http({
    method: 'patch',
    url: `${env('BASE_API_V3')}/employee-hotels/current`,
    data: { currentHotelId },
  }).then(results => results.data)
}

export const getStaffList = () => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/staff-manager`,
  }).then(results => results.data)
}

export const createStaff = (data: StaffInputType) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/staff-manager`,
    data,
  }).then(results => results.data)
}

export const updateStaff = (staffId: string | number, data: StaffInputType) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/staff-manager/${staffId}`,
    data,
  }).then(results => results.data)
}

export const deleteStaff = (staffId: string | number) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/staff-manager/${staffId}`,
  }).then(results => results.data)
}

export const getEmployeeStaff = () => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/staff`,
  }).then(results => results.data)
}

export const loginEmployeeStaff = (currentStaffId: string | number, passCode?: string) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/staff/login`,
    data: {
      currentStaffId,
      passCode,
    },
  }).then(results => results.data)
}

export const logoutEmployeeStaff = () => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/staff/logout`,
  }).then(results => results.data)
}

export const getOperationLogList = ({
  filters,
  page = 1,
  limit = 50,
}: { filters?: OperationLogFiltersType; page?: number; limit?: number } = {}) => {
  return http<{
    data: OperationLogType[]
    meta: {
      total: number
      current_page: number
      last_page: number
    }
  }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/operation-log`,
    params: {
      ...filters,
      page,
      limit,
    },
  }).then(results => results.data)
}

export const getHotelEmployeeList = () => {
  return http<{
    data: EmployeeType[]
  }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/employee-hotels/employees`,
  }).then(results => results.data)
}

export const getAssetList = () => {
  return http<AssetType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/guest-app/assets`,
  })
    .then(results => results.data)
    .catch(e => {
      console.log({ e })
      return []
    })
}
export const getEnabledAssetType = () => {
  return http<{ formatTypes: FormatType[] }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/guest-app/assets/type`,
  })
    .then(results => results.data.formatTypes)
    .catch(e => {
      console.log({ e })
      return []
    })
}

export const createAsset = (data: { title: string; formatType: FormatType }) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/guest-app/assets/`,
    data,
  })
}

export const updateAsset = (id: string, data: { title?: string; changeVisible?: boolean }) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/guest-app/assets/${id}`,
    data,
  })
}

export const deleteAsset = (id: string[]) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/guest-app/assets/bulk-delete`,
    params: { id },
  })
}

export const updateAssetDisplayOrder = (sortedIds: string[]) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/guest-app/assets/display-order`,
    data: { sortedIds },
  })
}

export const createAssetDetail = (
  id: string,
  type: FormatType,
  details:
    | AssetFreeDetailFormValue
    | AssetMapDetailFormValue
    | AssetNewsDetailFormValue
    | AssetCouponDetailFormValue
    | AssetProductDetailFormValue
    | SpaceCreateAndUpdateRequestType,
) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/guest-app/assets/${id}/detail`,
    data: {
      type,
      details,
    },
  })
}

export const updateAssetDetail = (
  id: string,
  detailId: string,
  type: FormatType,
  details:
    | AssetFreeDetailFormValue
    | AssetPulldownDetailFormValue
    | Partial<AssetMapDetailFormValue>
    | AssetNewsDetailFormValue
    | AssetButtonFormValue
    | AssetImageDetailFormValue
    | AssetCouponDetailFormValue
    | AssetProductDetailFormValue
    | SpaceCreateAndUpdateRequestType,
) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/guest-app/assets/${id}/detail/${detailId}`,
    data: {
      type,
      details,
    },
  })
}

export const deleteAssetDetail = (id: string, deleteDetailIds: string[] | number[]) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/guest-app/assets/${id}/detail/bulk-delete`,
    params: { id: deleteDetailIds },
  })
}

export const updateAssetDetailDisplayOrder = (id: string, sortedIds: string[] | number[]) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/guest-app/assets/${id}/detail/display-order`,
    data: { sortedIds },
  })
}

export const getAssetMapCategoryList = (assetId: string) => {
  return http<MapCategoryType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/guest-app/assets/${assetId}/map-categories`,
  })
    .then(results => results.data)
    .catch(e => {
      console.warn(e)
      return []
    })
}

export const updateAssetMapCategory = (assetId: string, id: string, data: { title: string; imagePath: string }) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/guest-app/assets/${assetId}/map-categories/${id}`,
    data,
  })
}

export const updateAssetMapCategoryDisplayOrder = (assetId: string, sortedIds: string[]) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/guest-app/assets/${assetId}/map-categories/display-order`,
    data: { sortedIds },
  })
}

export const updateAssetRequestCategoryDisplayOrder = (assetId: string, sortedIds: number[]) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/guest-app/assets/${assetId}/request-categories/display-order`,
    data: { sortedIds },
  })
}

export const updateAssetRequestTagDisplayOrder = (assetId: string, sortedIds: number[]) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/guest-app/assets/${assetId}/request-tags/display-order`,
    data: { sortedIds },
  })
}

export const updateAssetRequestSalesHourDisplayOrder = (assetId: string, sortedIds: number[]) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/guest-app/assets/${assetId}/request-sales-hours/display-order`,
    data: { sortedIds },
  })
}

export const getDesignTheme = () => {
  return http<DesignType>({
    method: 'get',
    url: `${env('BASE_API_V3')}/guest-app/designs`,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const previewDesignTheme = (params: ThemeType) => {
  return http<DesignType>({
    method: 'get',
    url: `${env('BASE_API_V3')}/guest-app/designs/preview`,
    params,
  })
    .then(results => results.data)
    .catch(e => console.warn(e))
}

export const updateDesignTheme = (data: ThemeType) => {
  return http<DesignType>({
    method: 'put',
    url: `${env('BASE_API_V3')}/guest-app/designs`,
    data,
  })
}

export const getMailDeliveryTemplates = () => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/mail-delivery-templates`,
  })
    .then(result => result.data)
    .catch(e => console.warn(e))
}

export const getMailDeliveryContents = () => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/mail-delivery-contents`,
  }).then(result => result.data)
}

export const getMailDeliveryContentDetail = (id: string) => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/mail-delivery-content/${id}`,
  }).then(result => result.data)
}

export const saveMailDeliveryContent = (data: MailDeliveryContentInput) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/mail-delivery-content`,
    data,
  }).then(result => result.data)
}

export const sendTestMailDeliveryContent = (testSendTo: string, data: MailDeliveryContentInput) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/mail-delivery-content/send-test`,
    data: {
      testSendTo,
      title: data.title,
      content: data.content,
    },
  })
}

export const updateMailDeliveryContent = (id: string, data: MailDeliveryContentInput) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/mail-delivery-content/${id}`,
    data,
  }).then(result => result.data)
}

export const deleteMailDeliveryContents = (params: { ids: string[] }) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/mail-delivery-contents/bulk-delete`,
    params,
  }).then(result => result.data)
}

export const getMailDeliverySetting = () => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/mail-delivery-setting`,
  }).then(result => result.data)
}

export const updateMailDeliverySetting = (data: { replyEmail: string }) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/mail-delivery-setting`,
    data,
  }).then(result => result.data)
}

export const getMailDeliveryHistory = (params: GetMailDeliveryHistoryParams) => {
  return http({
    method: 'get',
    url: `${env('BASE_API_V3')}/mail-delivery-histories`,
    params,
  }).then(result => result.data)
}

export const CreateSpaceReservation = (data: AlignmentReservationInput | NewReservationInput) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/spaces/reservations`,
    data,
  }).then(result => result.data)
}

export const updateSpaceReservation = (id: string, data: { assignSpaces: UpdateSpaceReservationInput[] }) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/spaces/reservations/${id}`,
    data,
  }).then(result => result.data)
}

export const getReservationSpaces = (params: { day: string }) => {
  return http<SpaceReservation[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/spaces/reservations`,
    params,
  }).then(result => result.data)
}
export const getSpaceByReservation = (reservationId: string) => {
  return http<SpaceReservation[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/spaces/reservations`,
    params: { reservationId },
  })
    .then(result => result.data)
    .catch(e => console.warn(e))
}
export const putSpaceAssignRoomKey = (data: {
  assign: { spaceId: string; spaceIndex: number; keyNumber: string | undefined; remoteDeviceId: string | undefined }[]
}) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/smart-lock/assign-key/spaces`,
    data,
  }).then(result => result.data)
}

export const getSalesInvoice = (reservationIds: string[]) => {
  return http<SalesInvoice.SalesType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/invoices/sales`,
    params: { reservationIds },
  })
    .then(result => result.data)
    .catch(e => {
      console.warn(e)
      return []
    })
}

export const getInvoice = (params?: SalesInvoice.InvoiceSearchType) => {
  return http<{
    total: number
    hasPrev: boolean
    hasNext: boolean
    result: SalesInvoice.InvoiceType[]
  }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/invoices`,
    params,
  })
    .then(result => result.data)
    .catch(e => console.warn(e))
}

export const createInvoice = (data: { clientName: string; paymentDate: string; note: string; salesIds: string[] }) => {
  return http<{ url: string }>({
    method: 'post',
    url: `${env('BASE_API_V3')}/invoices`,
    data,
  }).then(result => result.data)
}

export const changeInvoiceStatus = (
  invoiceId: string,
  data: {
    status: SalesInvoice.InvoiceStatusType
    isConfirmed?: boolean
    isForceUpdate?: boolean
  },
) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/invoices/${invoiceId}/status`,
    data,
  })
}

export const getSalesManagerDashboard = (params: { mode: 'daily' | 'monthly'; targetDay: Dayjs }) => {
  return http<DashboardSalesType>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/dashboard`,
    params: {
      mode: params.mode,
      targetDay: params.targetDay.format('YYYY-MM-DD'),
    },
  })
    .then(result => result.data)
    .catch(e => {
      console.warn(e)
      return undefined
    })
}

export const getSmartLockAccount = () => {
  return http<Array<AccountInfo>>({
    method: 'get',
    url: `${env('BASE_API_V3')}/smart-lock`,
  })
    .then(result => result.data)
    .catch(e => {
      console.warn(e)
      return []
    })
}

export const getSmartLockDevices = (params: { lockType: LockType }) => {
  return http<Array<Device>>({
    method: 'get',
    url: `${env('BASE_API_V3')}/smart-lock/devices`,
    params,
  })
    .then(result => result.data)
    .catch(e => {
      console.warn(e)
      return []
    })
}

export const getLockSettings = () => {
  return http<LockSetting[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/smart-lock/lock-settings`,
  })
    .then(result => result.data)
    .catch(e => {
      console.warn(e)
      return []
    })
}

export const updateLockSettings = (data: { roomKind: RoomKindType; lockType: LockType }) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/smart-lock/lock-settings`,
    data,
  }).then(result => result.data)
}

export const exportSalesAccountingReport = (params: {
  from: string
  to: string
  formatType: SalesAccountingModel.FormatType
  systemType: SalesAccountingModel.SystemType
}) => {
  return http<{ path: string | undefined }>({
    method: 'get',
    url: `${env('BASE_API_V3')}/sales-manager/sales-accounting/export`,
    params,
  })
    .then(result => result.data)
    .catch(e => {
      console.warn(e)
      return { path: undefined }
    })
}

export const fetchCustomerTagMasters = () => {
  return http<CustomerTagMasterType[]>({
    method: 'get',
    url: `${env('BASE_API_V3')}/customer-tag-master`,
  })
    .then(result => result.data)
    .catch(e => console.warn(e))
}

export const createCustomerTagMaster = (data: CustomerTag) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/customer-tag-master`,
    data,
  }).then(result => result.data)
}

export const deleteCustomerTagMaster = (ids: string[]) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/customer-tag-master/bulk-delete`,
    params: { ids },
  }).then(result => result.data)
}

export const updateCustomerTagMaster = (data: CustomerTag, id: string) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/customer-tag-master/${id}`,
    data,
  }).then(result => result.data)
}

export const updateCustomerTagMasterDisplayOrder = (sortedIds: string[]) => {
  return http({
    method: 'put',
    url: `${env('BASE_API_V3')}/customer-tag-masters/display-order`,
    data: { sortedIds },
  })
}

export const createCustomerTag = (data: { customerId: string; customerTagMasterIds: string[] }) => {
  return http({
    method: 'post',
    url: `${env('BASE_API_V3')}/customer-tag`,
    data,
  }).then(result => result.data)
}

export const deleteCustomerTag = (params: { customerId: string; customerTagMasterId: string }) => {
  return http({
    method: 'delete',
    url: `${env('BASE_API_V3')}/customer-tag`,
    params,
  }).then(result => result.data)
}
