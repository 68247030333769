import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@/components/atoms/modal'
import { useTranslation } from 'react-i18next'
import { ButtonType } from '@/components/atoms/button'
import { css } from '@emotion/core'
import { Button } from '@/components/atoms'
import { useCopyText } from '@/hooks/use-cpoy-text'

interface Props {
  onClose: () => void
  billAmount: number
  billLink: string
}
export const OnlineBillingModal: React.FC<Props> = ({ onClose, billAmount, billLink }) => {
  const { t } = useTranslation()
  const { copyText, CompleteToast } = useCopyText()

  return (
    <div>
      <Modal customCss={modalStyle}>
        <ModalHeader>{t('Online billing')}</ModalHeader>
        <ModalBody customCss={modalBodyStyle}>
          <div className="content-block">
            <div className="content-bill-block">
              <div className="detail-text">
                {t('URL for billing issued. Attach the link to an email and send it directly to the customer.')}
                <br />
                {t('When payment is completed, the statement will be changed to ‘settled’.')}
              </div>
              <div className="content-result-block">
                <div>
                  <div className="bill-amount-text">{t('Amount bill')}</div>
                  <div className="bill-amount-text">{billAmount.toLocaleString()}円</div>
                </div>
                <div>
                  <div className="bill-link-block">
                    <a href={billLink} target="_blank" rel="noreferrer noreferrer" className="bill-link-text">
                      {billLink}
                    </a>
                  </div>
                  <div className="bill-link-text" onClick={e => copyText(e, billLink)}>
                    {t('Copy')}
                  </div>
                </div>
              </div>
              <div className="annotation-text">
                {t('*The URL for the request will only appear once, so be sure to copy it before leaving the page.')}
              </div>
            </div>
            <div className="content-footer-block">
              <div>
                <a href="https://aipass.zendesk.com/hc/ja/articles/37223953806745-%E3%82%AD%E3%83%A3%E3%83%B3%E3%82%BB%E3%83%AB%E6%96%99%E3%81%AE%E8%AB%8B%E6%B1%82-%E5%A3%B2%E4%B8%8A%E8%A8%88%E4%B8%8A#h_01J6VNEQCBV7JWJEJ5VBD5ZD94" target="_blank" rel="noreferrer noreferrer" className="mail-link-text">
                  {t('Click here for email templates')}
                  <img style={{ paddingLeft: 8 }} height={9} src={require('@/static/images/room_manager_arrow.svg')} />
                </a>
              </div>
            </div>
          </div>
          <CompleteToast />
        </ModalBody>
        <ModalFooter>
          <Button buttonType={ButtonType.Primary} height="38px" width="126px" onClick={onClose}>
            {t('Copied.')}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const modalStyle = css({
  width: 600,
  height: 'auto',
  top: 'calc((100vh - 529px) / 2)',
})
const modalBodyStyle = css({
  '.content-block': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #CCCCCC',
    borderRadius: 5,
    '.content-bill-block': {
      padding: 32,
      borderBottom: 'border: 1px solid #CCCCCC',
    },
    '.content-result-block': {
      margin: '24px 0',
      border: '1px solid #CCCCCC',
      borderRadius: 5,
      overflow: 'hidden',
      '>div:first-of-type': {
        display: 'flex',
        backgroundColor: '#F5F5F5',
        padding: '18px 16px',
        alignItems: 'center',
      },
      '>div:last-of-type': {
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid #CCCCCC',
      },
      '.bill-link-block': {
        flexGrow: 1,
        borderRight: '1px solid #CCCCCC',
        alignSelf: 'center',
        overflow: 'auto',
      },
    },
    '.content-footer-block': {
      padding: '16px 32px',
      borderTop: '1px solid #CCCCCC',
    },
  },
  '.detail-text': {
    fontSize: 14,
    lineHeight: '21px',
    letterSpacing: 1.4,
    fontWeight: 500,
    color: '#272727',
  },
  '.bill-amount-text': {
    fontSize: 16,
    letterSpacing: 0.8,
    fontWeight: 'bold',
    color: '#272727',

    ':first-of-type': {
      paddingRight: 16,
      borderRight: '1px solid #CCCCCC',
      marginRight: 16,
      fontSize: 14,
    },
  },
  '.annotation-text': {
    fontSize: 12,
    lineHeight: '21px',
    letterSpacing: 1.2,
    fontWeight: 500,
    color: '#676767',
  },
  '.bill-link-text': {
    width: 'fit-content',
    minWidth: 'fit-content',
    fontSize: 12,
    letterSpacing: 1.2,
    fontWeight: 500,
    color: '#F2A40B',
    cursor: 'pointer',
    margin: '12px 16px',
  },
  '.mail-link-text': {
    width: 'fit-content',
    fontSize: 12,
    letterSpacing: 1.8,
    fontWeight: 'bold',
    color: '#F2A40B',
    cursor: 'pointer',
  },
})
