import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import * as api from '@/apis/aipass'

// constants
import { simpleTableStyle } from '@/constants/layout'

// components
import { sectionHeaderStyle } from '@/components/pages/dashboard/_index/style'
import { Button } from '@/components/atoms/button'
import { NotConfirmedUsageDetail } from '@/components/molecules/customer/sales-manager/usage-detail/not-confirmed-usage-detail'
import { ConfirmedUsageDetail } from '@/components/molecules/customer/sales-manager/usage-detail/confirmed-usage-detail'
import { UsageDetailSubject } from '@/components/molecules/customer/sales-manager/usage-detail/subjects'
import { DropDown } from '@/components/molecules/drop-down'

// models
import { EditSalesType, PaymentSettingType, SalesType, SubjectSettingType } from '@/models/sales'
import { ReservationType } from '@/models/reservation'
import { AccountsReceivableSettingType } from '@/models/accounts-receivable'
import { ApprovedStatus } from '@/models/reservation-approved-status'

// error
import { NOT_ENOUGH_BALANCE_CODE } from '@/errors/company-advance-money-error'

//libs
import { isSalesSubjectChange } from '@/libs/sales-subjects'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { AccountContext } from '@/contexts/account'
import { hasBackupReceiptPdfPlugin } from '@/libs/plugins'
import { InvoiceDetailModal } from '@/components/organisms/invoice-manager/invoice-detail-modal'
import { PaymentSettingType as ModelPaymentSettingType } from '@/models/payment-setting'
import { OnlineBillingModal } from './online-billing-modal'

type UsageDetailsProps = {
  checkinId?: string
  salesPayments: PaymentSettingType[]
  salesSubjects: SubjectSettingType[]
  totalUnpaid: number
  originUsageDetails: SalesType[]
  usageDetails: SalesType[]
  setUsageDetails: (v: SalesType[]) => void
  editUsageDetails: EditSalesType[]
  setEditUsageDetails: (v: EditSalesType[]) => void
  confirmedDay: string
  useCheckinDay: string
  setIsLoading: (v: boolean) => void
  fetchSales: () => void
  reservations: ReservationType[]
  accountsReceivableSettings: AccountsReceivableSettingType[]
  salesSubjectsWithoutSameSubjectCode: SubjectSettingType[]
  currentTabReservationId: string
  onUpdateUsageDetails: () => void
  receiptProps?: {
    customerId: string
    customerName: string
    customerLink?: any
    setIsReceiptModalOpen: (v: boolean) => void
    setIsSplitReceiptModalOpen: (v: boolean) => void
  }
}

export const UsageDetails: React.FC<UsageDetailsProps> = ({
  checkinId,
  salesPayments,
  salesSubjects,
  totalUnpaid,
  originUsageDetails,
  usageDetails,
  setUsageDetails,
  editUsageDetails,
  setEditUsageDetails,
  confirmedDay,
  useCheckinDay,
  setIsLoading,
  fetchSales,
  reservations,
  accountsReceivableSettings,
  salesSubjectsWithoutSameSubjectCode,
  currentTabReservationId,
  onUpdateUsageDetails,
  receiptProps,
}) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [deleteIds, setDeleteIds] = useState<{ id: string | undefined }[]>([])
  const [salesSubjectSetList, setSalesSubjectSetList] = useState<any>([])
  const [salesSubjectSetId, setSalesSubjectSetId] = useState<string>('')
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [advanceCompany, setAdvanceCompany] = useState<any>({ paymentCompanies: [], paymentMethod: [] })
  const [deletedUsageDetails, setDeletedUsageDetails] = useState<any>([])
  const [showSubjectSetSelect, setShowSubjectSetSelect] = useState<boolean>(false)
  const [isOpenInvoiceModal, setIsOpenInvoiceModal] = useState<boolean>(false)
  const [paymentSetting, setPaymentSetting] = useState<ModelPaymentSettingType>()
  const [paymentLinkIssueResult, setPaymentLinkIssueResult] = useState<{ amount: number; url: string }>()

  const { plugins } = useContext<any>(AccountContext)
  const backupReceiptPdfPluginActive = hasBackupReceiptPdfPlugin(plugins)
  const hasFullAssignedRoom =
    reservations?.length &&
    !reservations.some(
      reservation => !reservation?.assignedRoom?.length || reservation.assignedRoom.some(assignedRoom => !assignedRoom.guestRoomAssignId),
    )

  const hasUnpaidSales = useMemo(() => usageDetails?.some(i => !i.isPaid), [usageDetails])

  // Editing newly created courses
  const changeDate = (date, index) => {
    const _editUsageDetails = [...editUsageDetails]
    _editUsageDetails[index].salesDate = dayjs(date).format('YYYY-MM-DD')
    addIsEdit(_editUsageDetails[index])
    setEditUsageDetails(_editUsageDetails)
  }
  const changeState = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const _editUsageDetails = [...editUsageDetails]
    const value = e.target.value
    const name = e.target.name
    if (name === 'isPaid') {
      // Changes to "Unsettled" and "Settled"
      _editUsageDetails[index]['isPaid'] = Boolean(Number(value))
      // Change payment: Change to "0" for "unsettled" and "sales amount (unit price * quantity)" for "settled"
      const paymentPrice = value === '0' ? 0 : Number(_editUsageDetails[index]['salesSubjectPrice']) * _editUsageDetails[index]['quantity']
      _editUsageDetails[index]['salesPaymentPrice'] = paymentPrice
    } else {
      _editUsageDetails[index][name] = value
    }

    // Change 'Unit Price' or 'Quantity' && update payment amount if sales is 'settled'
    if (name === 'quantity') {
      _editUsageDetails[index]['quantity'] = Number(value) < 0 ? 0 : Number(value) || 0
    }
    if ((name === 'salesSubjectPrice' || name === 'quantity') && !!_editUsageDetails[index]['isPaid']) {
      _editUsageDetails[index]['salesPaymentPrice'] =
        Number(_editUsageDetails[index]['salesSubjectPrice']) * _editUsageDetails[index]['quantity']
    }

    // If there is an account receivable associated with the payment method, assign the account receivable ID of usageDetail together
    if (name === 'salesPaymentId') {
      autoCompleteAccountsReceivableWithSelectedSalesPayment(_editUsageDetails[index], value)
    }

    addIsEdit(_editUsageDetails[index])
    setEditUsageDetails(_editUsageDetails)
    // If there is a null salesPaymentId value in _editUsageDetails, hyphenate it there
    if (_editUsageDetails[index]['salesPaymentId'] === '') {
      _editUsageDetails[index]['salesPaymentId'] = salesPayments?.[0]?.['id']
    }
  }
  // Change the date of a saved course
  const changeNotConfirmedDate = (date, index) => {
    const _usageDetails = [...usageDetails]
    _usageDetails[index].salesDate = dayjs(date).format('YYYY-MM-DD')
    addIsEdit(_usageDetails[index])
    setUsageDetails(_usageDetails)
  }
  // Edit unconfirmed course
  const changeNotConfirmedState = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, index) => {
    const _usageDetails = [...usageDetails]
    const value = e.target.value
    const name = e.target.name
    if (name === 'isPaid') {
      // Changes to "Unsettled" and "Settled"
      _usageDetails[index]['isPaid'] = Boolean(Number(value))
      // Change payment: Change to "0" for "unsettled" and "sales amount (unit price * quantity)" for "settled"
      const paymentPrice = value === '0' ? 0 : Number(_usageDetails[index]['salesSubjectPrice']) * _usageDetails[index]['quantity']
      _usageDetails[index]['salesPaymentPrice'] = paymentPrice
    } else {
      _usageDetails[index][name] = value
    }

    if (name === 'salesSubjectId') {
      _usageDetails[index]['salesSubjectSetId'] = null
    }

    // Change 'Unit Price' or 'Quantity' && update payment amount if sales is 'settled'
    if (name === 'quantity') {
      _usageDetails[index]['quantity'] = Number(value) < 0 ? 0 : Number(value) || 0
    }
    if ((name === 'salesSubjectPrice' || name === 'quantity') && !!_usageDetails[index]['isPaid']) {
      _usageDetails[index]['salesPaymentPrice'] = Number(_usageDetails[index]['salesSubjectPrice']) * _usageDetails[index]['quantity']
    }
    // If there is an account receivable associated with the payment method, assign the account receivable ID of usageDetail together
    if (name === 'salesPaymentId') {
      autoCompleteAccountsReceivableWithSelectedSalesPayment(_usageDetails[index], value)
    }
    if (name === 'companyId' && value) {
      autoCompleteAccountsReceivableWithSelectedSalesPayment(_usageDetails[index], value)
    }

    addIsEdit(_usageDetails[index])
    setUsageDetails(_usageDetails)
  }

  const autoCompleteAccountsReceivableWithSelectedSalesPayment = (
    usageDetail: SalesType | EditSalesType,
    selectedSalesPaymentOrCompanyId: string,
  ) => {
    const selectedSalesPayment = salesPayments.find(salesPayment => {
      return salesPayment.id === selectedSalesPaymentOrCompanyId
    })
    const selectedCompany = advanceCompany?.paymentCompanies?.find(company => {
      return company.id === selectedSalesPaymentOrCompanyId
    })
    const alignmentAccountReceivable = accountsReceivableSettings?.find(receivable => {
      return receivable.code === selectedSalesPayment?.methodCode || receivable.id === selectedCompany?.salesAccountsReceivableMasterId
    })
    usageDetail['salesAccountsReceivableMasterId'] = alignmentAccountReceivable ? alignmentAccountReceivable.id : ''
  }

  const changeNotConfirmedSalesPrice = (price: number, index) => {
    const _usageDetails = [...usageDetails]
    _usageDetails[index]['salesSubjectPrice'] = price
    addIsEdit(_usageDetails[index])
    setUsageDetails(_usageDetails)
  }

  const addUsageDetail = () => {
    setIsEditMode(true)
    const currentReservation = reservations.find(reservation => reservation.reservationId === currentTabReservationId)
    const isStayOrCO =
      currentReservation?.approvedStatus === +ApprovedStatus.Stay || currentReservation?.approvedStatus === +ApprovedStatus.Checkout

    const newUsageDetails: EditSalesType = {
      accommodationId: '',
      salesDate: isStayOrCO ? dayjs().format('YYYY-MM-DD') : useCheckinDay,
      salesSubjectId: '',
      salesSubjectPrice: 0,
      quantity: 1,
      salesPaymentId: '',
      salesPaymentPrice: 0,
      isEdit: true,
      isPaid: false,
      reservationId: currentTabReservationId,
      checkinId,
      salesSubSubjectId: '',
      salesSubjectCode: '',
      salesSubSubjectCode: '',
      salesAccountsReceivableMasterId: '',
      salesAccountsReceivableName: '',
    }
    setEditUsageDetails([...editUsageDetails, newUsageDetails])
  }

  const deleteUsageDetail = (index: number) => {
    const newDetails = [...usageDetails]
    setDeletedUsageDetails([...deletedUsageDetails, newDetails[index]])
    newDetails.splice(index, 1)
    setUsageDetails(newDetails)
    setDeleteIds([...deleteIds, { id: usageDetails[index].id }])
  }

  const deleteUsageDetailSubject = index => {
    const newSubjectDetails = [...editUsageDetails]
    newSubjectDetails.splice(index, 1)
    setEditUsageDetails(newSubjectDetails)
  }

  // Newly created and edited item flags
  const addIsEdit = (array: SalesType | EditSalesType) => {
    const usageDetailById = originUsageDetails.find(originUsageDetail => originUsageDetail.id === array.id)
    const usageDetailsWithoutIsEdit = { ...array }
    delete usageDetailsWithoutIsEdit['isEdit']
    if (isSalesSubjectChange(usageDetailById, usageDetailsWithoutIsEdit)) {
      array['isEdit'] = true
    } else {
      array['isEdit'] = false
    }
  }

  const isDirtySalesOnConfirmed = (originSales: SalesType, editSales: EditSalesType | SalesType): boolean => {
    if (!originSales.isConfirm) {
      return false
    }
    return (
      originSales.salesDate !== editSales.salesDate ||
      Number(originSales.salesSubjectPrice) !== Number(editSales.salesSubjectPrice) ||
      Number(originSales.quantity) !== Number(editSales.quantity)
    )
  }

  const saveUsageDetail = () => {
    // Deep copy modified and newly created data
    const _editUsageDetails = [...usageDetails, ...editUsageDetails].map(value => ({ ...value }))
    // Saved array: data that has not been changed or newly created is deleted from the array
    const saveUsageDetails = _editUsageDetails.filter(sales => sales['isEdit'] === true)
    let isEditConfirmedSales = false

    // Edit confirmed data → Add to history array, Edit unconfirmed data && Edit deposit → Set deposit date to null
    originUsageDetails.forEach(originSales => {
      if (deleteIds.findIndex(({ id }) => id === originSales.id) !== -1 && originSales.isConfirm) {
        isEditConfirmedSales = true
        return
      }
      // Get the edit item index with the same id as "original data id"
      const indexOfSameId = saveUsageDetails.findIndex(sales => sales.id === originSales.id)
      if (indexOfSameId === -1) return
      isEditConfirmedSales = isEditConfirmedSales || isDirtySalesOnConfirmed(originSales, saveUsageDetails[indexOfSameId])
      if (saveUsageDetails[indexOfSameId]['isPaid'] !== originSales.isPaid) {
        // Set the payment date (paymentSalesManagerDailyId) to null when editing pending items and changing "settled" or "unsettled"
        saveUsageDetails[indexOfSameId]['paymentSalesManagerDailyId'] = null
      }
    })

    // If the date of the saved data has been confirmed, add it to the history array
    saveUsageDetails.forEach(usageDetail => {
      usageDetail['salesSubjectPrice'] = Number(usageDetail['salesSubjectPrice']) || 0
      usageDetail['quantity'] = Number(usageDetail['quantity']) || 0
    })

    // If the saved data is caught in validation, display an alert and end the saving process
    if (checkCompletedInput(saveUsageDetails)) {
      alert(
        t('Please select the subject payment method and accommodation ID Please enter the unit price and quantity in single-byte numbers'),
      )
      return
    }

    // Combining "create new, modify" and "delete" (data sent to API)
    const updateUsageDetails = [...saveUsageDetails, ...deleteIds]

    // If there is no data, exit edit mode and end save processing
    if (updateUsageDetails.length === 0) {
      setIsEditMode(false)
      return
    }

    if (
      isEditConfirmedSales &&
      !window.confirm(t('The date on which the closing process was closed. Sales totals will change, do you want to save them?'))
    ) {
      return
    }

    setIsLoading(true)
    api
      .updateSales(updateUsageDetails)
      .then(async () => {
        if (receiptProps) {
          const checkIsPaid = updateUsageDetails.some(item => (item as any).isPaid == true)
          const checkDeletedUsageDetails = deletedUsageDetails.some(item => (item as any).isPaid == true)
          if (checkIsPaid || checkDeletedUsageDetails) {
            const provision = ''
            api.backupReceiptPDF(receiptProps.customerId, receiptProps.customerName, provision)
          }
          setDeletedUsageDetails([])
        }
        fetchSales()
        onUpdateUsageDetails()
        setIsLoading(false)
        setEditUsageDetails([])
        setDeleteIds([])
        setIsEditMode(false)
      })
      .catch(error => {
        if (error?.response?.data?.errorCode === NOT_ENOUGH_BALANCE_CODE) {
          alert(t('Insufficient balance Please check again'))
        } else {
          console.log(t('Communication failed'))
          setEditUsageDetails([])
          setIsEditMode(false)
        }
        setIsLoading(false)
      })
  }

  // Cancellation process
  const resetUsageDetails = () => {
    setUsageDetails(originUsageDetails.map(value => ({ ...value })))
    // state initialization
    setEditUsageDetails([])
    setDeleteIds([])
    setIsEditMode(false)
  }

  // Are the subject and payment method entered?
  const checkCompletedInput = editUsageDetails => {
    const isEmptySalesSubjectId = editUsageDetails.some(editUsageDetail => editUsageDetail.salesSubjectId === '')
    const isEmptySalesPaymentId = editUsageDetails.some(editUsageDetail => editUsageDetail.salesPaymentId === '')
    const isEmptySalesSubjectReservationId = editUsageDetails.some(editUsageDetail => editUsageDetail.reservationId === '' || null)
    // Unit price: salesSubjectPrice
    const isHalfSizeNumberSalesSubjectPrice = editUsageDetails.some(
      editUsageDetail => !String(editUsageDetail.salesSubjectPrice).match(/^-?[0-9]+$/),
    )
    // Quantity : quantity
    const isHalfSizeNumberQuantity = editUsageDetails.some(editUsageDetail => !String(editUsageDetail.quantity).match(/^[0-9]+$/))
    if (
      isEmptySalesSubjectId ||
      isEmptySalesPaymentId ||
      isEmptySalesSubjectReservationId ||
      isHalfSizeNumberSalesSubjectPrice ||
      isHalfSizeNumberQuantity
    ) {
      return true
    } else {
      return false
    }
  }

  const createSubjectSet = async () => {
    try {
      setIsLoading(true)

      const res = await api.fetchSalesAccountSubjectSet({ checkinId, reservationId: currentTabReservationId, salesSubjectSetId })

      setEditUsageDetails([
        ...editUsageDetails,
        ...res.salesGroupBySalesDate.map(
          (i): EditSalesType => ({
            ...i,
            salesSubjectId: i.isDisplay ? i.salesSubjectId : '',
            isEdit: true,
            salesPaymentId: salesPayments?.[0]?.id,
            salesPaymentPrice: 0,
            salesAccountsReceivableMasterId: '',
            salesAccountsReceivableName: '',
            salesSubSubjectId: '',
            salesSubSubjectCode: '',
            isPaid: false,
            salesSubjectSetId,
          }),
        ),
      ])
      setIsEditMode(true)
      cancelSubjectSet()
      const hasDeletedSetItem = res.salesGroupBySalesDate.some(sales => !sales.isDisplay)
      if (hasDeletedSetItem) {
        window.alert(t('Subjects in the subject set have been changed\nPlease update your subject set'))
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const cancelSubjectSet = () => {
    setSalesSubjectSetId('')
    setShowSubjectSetSelect(false)
  }

  const doOnlineBilling = async () => {
    if (!paymentSetting?.card_api_token) {
      alert(
        t(
          'This service is free of charge, but requires prior application to the payment agent. Please contact our support team if you wish to use this service.',
        ),
      )
      return
    }
    if (!window.confirm(t('Do you want to issue a billing link?'))) {
      return
    }

    try {
      setIsLoading(true)
      const issueResult = await api.issueExternalLinkPayment({
        checkinId,
        reservationId: currentTabReservationId,
      })
      setPaymentLinkIssueResult(issueResult)
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    api.fetchAdvanceCompany().then(setAdvanceCompany)
    api.fetchSalesSubjectSet().then(res => setSalesSubjectSetList(res?.salesSubjectSet || []))
    api.fetchPaymentSetting().then(res => res && setPaymentSetting(res.settings))
  }, [])

  return (
    <>
      <div css={headerContainerStyle}>
        <div css={leftContainerStyle}>
          <div css={headerStyle}>{t('Usage details')}</div>
          {currentTabReservationId && totalUnpaid > 0 && <div css={rightTopStatusStyle}>{t('With accounts receivable')}</div>}
        </div>

        <div css={rightContainerStyle}>
          {!!usageDetails?.length && (
            <>
              <DropDown
                buttonStyle={css({
                  width: 78,
                  height: 32,
                  borderRadius: 23,
                  alignContent: 'center',
                  img: { paddingRight: 16 },
                  marginRight: 16,
                })}
                title={t('Bill')}
                items={[
                  { title: t('Issue invoice'), onClick: () => setIsOpenInvoiceModal(true) },
                  { title: t('Online billing'), onClick: () => doOnlineBilling(), disabled: !totalUnpaid },
                ]}
              />

              {receiptProps && currentTabReservationId && (
                <DropDown
                  buttonStyle={css({
                    width: 91,
                    height: 32,
                    borderRadius: 23,
                    alignContent: 'center',
                    img: { paddingRight: 16 },
                  })}
                  title={t('receipt')}
                  items={[
                    ...(hasUnpaidSales
                      ? [
                          {
                            title: t('issue'),
                            icon: require('@/static/images/file_download.svg'),
                            onClick: () => receiptProps.setIsReceiptModalOpen(true),
                          },
                        ]
                      : []),
                    { title: t('split issue'), onClick: () => receiptProps.setIsSplitReceiptModalOpen(true) },
                    ...(backupReceiptPdfPluginActive
                      ? [
                          {
                            title: t('issue history'),
                            onClick: () => {
                              if (checkinId) history.push(`/sales-manager/receipt-history/list?checkinId=${checkinId}`)
                              else if (receiptProps.customerLink?.cuicinUserId)
                                history.push(`/sales-manager/receipt-history/list?customerId=${receiptProps.customerLink?.cuicinUserId}`)
                              else if (currentTabReservationId) {
                                history.push(`/sales-manager/receipt-history/list?reservationId=${currentTabReservationId}`)
                              }
                            },
                          },
                        ]
                      : []),
                  ]}
                />
              )}
            </>
          )}
        </div>
      </div>
      <table css={simpleTableStyle} style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th css={{ width: '112px' }}>{t('Date')}</th>
            <th css={{ width: '12.5%' }}>{t('Subject')}</th>
            <th css={{ width: '7.5%' }}>{t('Supplementary subjects')}</th>
            <th css={{ width: '12.5%' }}>{t('Accommodation ID/Room Number')}</th>
            <th css={{ width: '7.5%' }}>{t('Unit price')}</th>
            <th css={{ width: '6%' }}>{t('Quantity')}</th>
            <th css={{ width: '6%' }}>{t('Earnings')}</th>
            <th css={{ width: '13.5%' }}>{t('Method of payment')}</th>
            <th css={{ width: '8%' }}>{t('Payment')}</th>
            <th css={{ width: '13.5%' }}>{t('Accounts receivable')}</th>
            <th css={{ width: '5%' }}></th>
          </tr>
        </thead>
        <tbody>
          {usageDetails &&
            currentTabReservationId &&
            usageDetails.map((usageDetail, index) => {
              if (!Object(usageDetail).salesDate || !currentTabReservationId || !accountsReceivableSettings) {
                return null
              }
              const isFixSales = (usageDetail.isConfirm && usageDetail.isPaid) || usageDetail.hasSentPos
              return (
                <React.Fragment key={index}>
                  {!Object(usageDetail).isEdit && isFixSales ? (
                    <ConfirmedUsageDetail
                      key={index}
                      index={index}
                      usageDetail={usageDetail}
                      confirmedDay={confirmedDay}
                      setIsEditMode={setIsEditMode}
                      changeNotConfirmedDate={changeNotConfirmedDate}
                      accountsReceivableSettings={accountsReceivableSettings}
                      advanceCompany={advanceCompany}
                    />
                  ) : (
                    <NotConfirmedUsageDetail
                      key={index}
                      index={index}
                      advanceCompany={advanceCompany}
                      usageDetail={usageDetail}
                      salesSubjects={salesSubjects}
                      salesPayments={salesPayments}
                      confirmedDay={confirmedDay}
                      isEditMode={isEditMode}
                      changeNotConfirmedDate={changeNotConfirmedDate}
                      changeNotConfirmedSalesPrice={changeNotConfirmedSalesPrice}
                      changeNotConfirmedState={changeNotConfirmedState}
                      deleteUsageDetail={deleteUsageDetail}
                      setIsEditMode={setIsEditMode}
                      accountsReceivableSettings={accountsReceivableSettings}
                      reservations={reservations}
                      salesSubjectsWithoutSameSubjectCode={salesSubjectsWithoutSameSubjectCode}
                      currentTabReservationId={currentTabReservationId}
                    />
                  )}
                </React.Fragment>
              )
            })}
          {/* Newly added subject */}
          {editUsageDetails &&
            currentTabReservationId &&
            editUsageDetails.map((editUsageDetail, index) => (
              <UsageDetailSubject
                key={index}
                index={index}
                editUsageDetail={editUsageDetail}
                advanceCompany={advanceCompany}
                salesSubjects={salesSubjects}
                salesPayments={salesPayments}
                confirmedDay={confirmedDay}
                changeDate={changeDate}
                changeState={changeState}
                deleteUsageDetailSubject={deleteUsageDetailSubject}
                accountsReceivableSettings={accountsReceivableSettings}
                reservations={reservations}
                salesSubjectsWithoutSameSubjectCode={salesSubjectsWithoutSameSubjectCode}
                currentTabReservationId={currentTabReservationId}
              />
            ))}
        </tbody>
      </table>

      <div css={borderStyle}>
        {currentTabReservationId ? (
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            {showSubjectSetSelect ? (
              <div
                css={{
                  width: 'calc(50% - 40px)',
                  margin: '0 8px 0 32px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <select value={salesSubjectSetId} onChange={e => setSalesSubjectSetId(e.target.value)} css={selectStyle}>
                  <option value="" disabled>
                    {t('Please select')}
                  </option>
                  {salesSubjectSetList.map(value => (
                    <option key={value.salesSubjectSetId} value={value.salesSubjectSetId}>
                      {value.salesSubjectSetName}
                    </option>
                  ))}
                </select>
                <div css={{ display: 'flex', alignItems: 'center' }}>
                  <Button buttonType={3} width={80} height={32} fontSize={12} marginRight={16} marginLeft={16} onClick={cancelSubjectSet}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    buttonType={!salesSubjectSetId ? 5 : 1}
                    width={80}
                    height={32}
                    isDisabled={!salesSubjectSetId}
                    fontSize={12}
                    onClick={createSubjectSet}
                  >
                    {t('Create')}
                  </Button>
                </div>
              </div>
            ) : (
              <div
                css={[
                  addRoomListWrapperOpenHasRoomManagerPluginStyle,
                  { marginRight: 8, ...(!hasFullAssignedRoom ? { pointerEvents: 'none' } : {}) },
                ]}
                onClick={() => setShowSubjectSetSelect(true)}
              >
                <img
                  src={!hasFullAssignedRoom ? require('@/static/images/plus_gray.svg') : require('@/static/images/plus_yellow.svg')}
                  alt={t('Add icon yellow')}
                />
                <p css={!hasFullAssignedRoom ? { color: '#ccc !important' } : {}}>{t('Add subject set')}</p>
              </div>
            )}
            <div css={[addRoomListWrapperOpenHasRoomManagerPluginStyle, { marginLeft: 8 }]} onClick={addUsageDetail}>
              <img src={require('@/static/images/plus_yellow.svg')} alt={t('Add icon yellow')} />
              <p>{t('Add subject')}</p>
            </div>
          </div>
        ) : (
          <p css={isEmptyReservationRoomListWrapperStyle}></p>
        )}
      </div>
      {isEditMode && (
        <div css={saveButtonStyle}>
          <Button buttonType={3} width={80} height={32} fontSize={12} marginRight={14} marginBottom={16} onClick={resetUsageDetails}>
            {t('Cancel')}
          </Button>
          <Button width={80} height={32} buttonType={1} fontSize={12} marginRight={32} marginBottom={16} onClick={saveUsageDetail}>
            {t('Save')}
          </Button>
        </div>
      )}

      {isOpenInvoiceModal && <InvoiceDetailModal onClose={() => setIsOpenInvoiceModal(false)} initialReservations={reservations} />}
      {paymentLinkIssueResult && (
        <OnlineBillingModal
          onClose={() => setPaymentLinkIssueResult(undefined)}
          billAmount={paymentLinkIssueResult?.amount}
          billLink={paymentLinkIssueResult?.url}
        />
      )}
    </>
  )
}

const headerStyle = css(sectionHeaderStyle, {
  height: 50,
  marginLeft: 28,
})

const headerContainerStyle = css({
  marginRight: 32,
  display: 'flex',
  justifyContent: 'space-between',
})

const leftContainerStyle = css({
  display: 'flex',
  float: 'left',
  alignItems: 'center',
})

const rightContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
})

const rightTopStatusStyle = css({
  height: 25,
  minWidth: 102,
  border: '1px solid #F2A40B',
  borderRadius: 5,
  color: '#F2A40B',
  fontSize: 12,
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '6px',
})

const saveButtonStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '24px',
  paddingBottom: '8px',
})

const borderStyle = css({
  borderTop: '1px solid ##F2F2F2',
  borderBottom: '1px solid #F2F2F2',
  marginBottom: 24,
})

const addRoomListWrapperStyle = css({
  cursor: 'pointer',
  display: 'flex',
  height: 36,
  backgroundColor: '#FAFAFA',
  justifyContent: 'center',
  border: '1px dashed #CCCCCC',
  p: {
    lineHeight: '36px',
    color: '#F2A40B',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
})

const addRoomListWrapperOpenHasRoomManagerPluginStyle = css(addRoomListWrapperStyle, {
  margin: '32px 24px',
  flex: 1,
  img: {
    width: 20,
  },
  ':hover': {
    background: '#F2F2F2',
  },
})

const isEmptyReservationRoomListWrapperStyle = css({
  display: 'flex',
  height: 36,
  aliginItemsCenter: 'center',
  margin: '32px 30px',
  p: {
    lineHeight: '36px',
    color: '#676767',
    fontSize: 14,
    paddingLeft: 8,
    letterSpacing: '1.4px',
  },
})

const selectStyle = css({
  width: '100%',
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/arrow_yellow.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  display: 'block',
  paddingLeft: 14,
  paddingRight: 5,
  backgroundPosition: '95% center',
})
