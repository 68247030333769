import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

import { CustomerSearchType } from '@/models/customer-manager/customer'
import { Button } from '@/components/atoms/button'
import dayjs from 'dayjs'

type SuggestCustomerProps = {
  setIsOpenModal: (v: boolean) => void
  customers: CustomerSearchType[]
  onSaveCustomerLink: (v: string) => Promise<void>
}

export const SuggestCustomer: React.FC<SuggestCustomerProps> = ({ setIsOpenModal, customers, onSaveCustomerLink }) => {
  const { t } = useTranslation()

  return (
    <>
      <div
        css={{
          height: 48,
          borderBottom: '1px solid #f5f5f5',
          padding: '0 32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontWeight: 'bold',
          fontSize: 16,
        }}
      >
        <div css={{ color: '#272727' }}>{t('Customer')}</div>
        <div>
          <Button buttonType={4} fontSize={11} width={32} height={32} onClick={() => setIsOpenModal(true)}>
            <img alt="close" src={require('@/static/images/magnify.svg')} />
          </Button>
        </div>
      </div>
      <div css={SectionStyle}>
        {customers?.map(customer => (
          <div key={customer.id} css={SectionItemStyle}>
            <div css={leftSectionStyle}>
              <div css={nameStyle}>
                <span className="furigana">{customer.nameKana ? customer.nameKana : '-'}</span>
                <span>{customer.name ? customer.name : '-'}</span>
              </div>
              <div css={attributeStyle}>
                <div css={itemWrapperStyle}>
                  <img src={require('@/static/images/phone.svg')} />
                  <p>{customer?.telephone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1$2$3')}</p>
                </div>

                <div css={itemWrapperStyle}>
                  <img className="icon_hotel" src={require('@/static/images/hotel.svg')} />
                  <p css={{ overflow: 'hidden', whiteSpace: 'nowrap', width: 100, textOverflow: 'ellipsis' }}>
                    {customer?.hotelName ?? '-'}
                  </p>
                </div>

                <div css={itemWrapperStyle}>
                  <img src={require('@/static/images/operation-log/icon_menu.svg')} />
                  <p>{customer?.createdAt ? dayjs(customer.createdAt).format('YYYY年MM月DD日') : '-'}</p>
                </div>
              </div>
            </div>
            <div css={linkButtonStyle} onClick={() => onSaveCustomerLink(customer?.id)}>
              {t('Confirm')}
            </div>
          </div>
        ))}
        <div css={searchLinkWrapperStyle}>
          <div css={searchLinkStyle} onClick={() => setIsOpenModal(true)}>
            <img src={require('@/static/images/magnify.svg')} alt={t('Add icon red')} css={addIconStyle} />
            <p>{t('Customer search')}</p>
          </div>
        </div>
      </div>
    </>
  )
}

const textStyle = css({
  color: '#676767',
  fontSize: 12,
})

const titleStyle = css(textStyle, {
  fontWeight: 'bold',
  marginBottom: 14,
})

const SectionStyle = css({
  backgroundColor: '#FFF',
  padding: 24,
  position: 'relative',
  overflow: 'scroll',
  flex: '1 1 0px',
})

const SectionItemStyle = css({
  borderRadius: 10,
  padding: '13px 22px',
  marginBottom: 9,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#F5F5F5',
  transition: 'all 0.3s ease',
  ':hover': {
    backgroundColor: '#E5E5E5',
  },
})

const leftSectionStyle = css({
  display: 'flex',
  alignItems: 'center',
})

const itemWrapperStyle = css(textStyle, {
  display: 'flex',
  marginTop: 5,
  img: {
    marginRight: 4,
    width: 12,
    '&.icon_hotel': {
      width: 14,
    },
  },
  p: {
    fontSize: 12,
    height: 20,
    lineHeight: '21px',
  },
})

const nameStyle = css({
  flex: '0 0 112px',
  fontWeight: 'bold',
  span: {
    display: 'block',
    wordBreak: 'break-all',
  },
  '.furigana': {
    fontSize: 10,
    marginBottom: 8,
    color: '#676767',
  },
})

const linkButtonStyle = css({
  fontSize: 12,
  color: '#F2A40B',
  border: '1px solid #CCCCCC',
  borderRadius: 23,
  width: 54,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: '#FFF',
})

const searchLinkWrapperStyle = css({
  width: '100%',
})

const searchLinkStyle = css({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
  backgroundColor: '#F5F5F5',
  border: '1px dashed #CCCCCC',
  padding: '20px 0 24px 0',
  borderRadius: 10,
  p: {
    color: '#F2A40B',
    fontSize: 14,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
})

const addIconStyle = css({
  width: 20,
  height: 20,
  marginLeft: 5,
})

const attributeStyle = css({
  flex: '0 0 120px',
})
