export const PaymentIndex = {
  Checkin: 'CHECKIN',
  Checkout: 'CHECKOUT',
  MobileRequest: 'MOBILE_REQUEST',
  SelfCheckin: 'SELF_CHECKIN',
  ReserveSpace: 'RESERVE_SPACE',
} as const
export type PaymentIndex = (typeof PaymentIndex)[keyof typeof PaymentIndex]

export const PaymentMethodType = {
  Credit: 'CREDIT_CARD',
  Front: 'DIRECT',
} as const
export type PaymentMethodType = (typeof PaymentMethodType)[keyof typeof PaymentMethodType]

export type PaymentInfoType = {
  id: string
  value: PaymentIndex
  is_enabled: number
  payment_method: Array<{ value: PaymentMethodType; is_enabled: number }>
}

export type PaymentSettingType = {
  card_api_token: string | undefined
  payment_id: {
    id: string
    value: string
  }
  payment_time: PaymentInfoType[]
}
